import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-679f8548"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "allRecipes" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "container-center-header" }
const _hoisted_4 = { class: "container-add-recipe" }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_RecipeBlocksByTagView = _resolveComponent("RecipeBlocksByTagView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PageHeader, { "header-text": "My recipes" }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/Recipe/Add" }, {
            default: _withCtx(() => [
              _createTextVNode(" Create new recipe ")
            ]),
            _: 1
          })
        ])
      ])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
      : (_ctx.allRecipesByTag.length > 0)
        ? (_openBlock(), _createBlock(_component_RecipeBlocksByTagView, {
            key: 1,
            "show-filter": true,
            "show-favorites": true,
            onUpdatedFilterTags: _ctx.filterRecipesOnTags,
            recipes: _ctx.filteredRecipesByTags
          }, null, 8, ["onUpdatedFilterTags", "recipes"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_5, "Create your first recipe!"))
  ]))
}