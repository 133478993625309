import { Bug, BugStatus } from "@/types/Bug";

export const sortBugs = (bugs: Bug[]): Bug[] => {
  const allResolved = [...bugs]
    .filter((bug) => bug.status === BugStatus.RESOLVED)
    .sort(function (a, b) {
      return (
        new Date(b.reportDate).getTime() - new Date(a.reportDate).getTime()
      );
    });
  const allProgress = [...bugs]
    .filter((bug) => bug.status === BugStatus.IN_PROGRESS)
    .sort(function (a, b) {
      return (
        new Date(b.reportDate).getTime() - new Date(a.reportDate).getTime()
      );
    });
  const allOpen = [...bugs]
    .filter((bug) => bug.status === BugStatus.OPEN)
    .sort(function (a, b) {
      return (
        new Date(b.reportDate).getTime() - new Date(a.reportDate).getTime()
      );
    });
  return [...allProgress, ...allOpen, ...allResolved];
};
