import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-051fc4e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "wrapper-walkthrough"
}
const _hoisted_2 = { class: "signout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_WalkthroughSlideTemplate = _resolveComponent("WalkthroughSlideTemplate")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
    : (_ctx.sliderIndex !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.signout && _ctx.signout(...args)))
            }, "Signout")
          ]),
          _createVNode(_component_WalkthroughSlideTemplate, {
            onUpdateIndex: _ctx.updateIndex,
            title: _ctx.data[_ctx.sliderIndex].title,
            "sub-text": _ctx.data[_ctx.sliderIndex].subText,
            index: _ctx.sliderIndex,
            "button-next-label": _ctx.sliderIndex === _ctx.data.length - 1 ? 'Finish' : 'Next'
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.data[_ctx.sliderIndex].template), _mergeProps(_ctx.data[_ctx.sliderIndex].config, { key: _ctx.sliderIndex }), null, 16))
            ]),
            _: 1
          }, 8, ["onUpdateIndex", "title", "sub-text", "index", "button-next-label"])
        ]))
      : _createCommentVNode("", true)
}