
import TagsContainer from "@/components/recipe/components/TagsContainer.vue";
import { Options, Vue } from "vue-class-component";
import { Tag } from "@/types/Tag";

@Options({
  components: { TagsContainer },
  emits: ["updateRecipeData"],
  props: {
    tags: Array,
    authorId: String,
    selectedTags: Array,
  },
  beforeMount() {
    this.nSelectedTags = this.selectedTags;
  },
  methods: {
    updateParent() {
      this.$emit("updateRecipeData", {
        key: "tags",
        value: this.nSelectedTags.map((tag: Tag) => tag._id),
      });
    },
  },
})
export default class RecipeTags extends Vue {
  nSelectedTags: Tag[] = [];
}
