
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { getAllBugs } from "@/components/FirebaseQueries";
import BugsListItem from "@/components/bugs/BugsListItem.vue";
import { Options, Vue } from "vue-class-component";
import { sortBugs } from "@/utils/SortUtils";

@Options({
  components: { BugsListItem, LoaderCircle },
  data() {
    return {
      loading: true,
      bugs: [],
    };
  },
  mounted() {
    this.loadBugs();
  },
  methods: {
    async loadBugs() {
      this.loading = true;
      this.bugs = await getAllBugs();
      this.loading = false;
    },
    updateBugs() {
      this.bugs = sortBugs(this.bugs);
    },
  },
})
export default class BugsListView extends Vue {}
