import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35c346aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-content" }
const _hoisted_2 = { class: "container-search-bar" }
const _hoisted_3 = {
  key: 1,
  class: "container-add-friends"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_AddFriendsList = _resolveComponent("AddFriendsList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        class: "search-bar",
        type: "text",
        maxlength: "30",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.oninputSearchUsers && _ctx.oninputSearchUsers(...args))),
        placeholder: "Search username or email"
      }, null, 544), [
        [_vModelText, _ctx.searchValue]
      ])
    ]),
    (_ctx.users.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_AddFriendsList, {
            onOnFriendMutated: _ctx.loadUsers,
            users: _ctx.users
          }, null, 8, ["onOnFriendMutated", "users"])
        ]))
      : _createCommentVNode("", true)
  ]))
}