import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4558cb1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "wrapper-block-profile-friend"
}
const _hoisted_2 = {
  key: 0,
  class: "remove-friend-container"
}
const _hoisted_3 = { class: "container-clickable" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_UserRemoveIcon = _resolveComponent("UserRemoveIcon")!
  const _component_UserAddIcon = _resolveComponent("UserAddIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.viewOnly && _ctx.user._id !== _ctx.contextUserId)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                ([_ctx.statusPending, _ctx.statusAccepted].includes(_ctx.status))
                  ? (_openBlock(), _createBlock(_component_UserRemoveIcon, {
                      key: 0,
                      onClick: _ctx.removeFriend
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                ([_ctx.statusUnknown, _ctx.statusAwaitingResponse].includes(_ctx.status))
                  ? (_openBlock(), _createBlock(_component_UserAddIcon, {
                      key: 1,
                      onClick: _ctx.sendFriendRequest
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_router_link, {
            class: _normalizeClass(`${!_ctx.linkable ? 'disabled-link' : ''}`),
            to: `/Friend/${_ctx.user._id}`
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: 
            _ctx.user.profilePicture === null
              ? require('@/assets/profile/placeholder_profile_picture.png')
              : _ctx.user.profilePicture
          ,
                  alt: ""
                }, null, 8, _hoisted_4),
                _createElementVNode("p", null, _toDisplayString(_ctx.user.displayName), 1)
              ])
            ]),
            _: 1
          }, 8, ["class", "to"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}