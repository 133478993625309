import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_RecipeForm = _resolveComponent("RecipeForm")!

  return (_ctx.loading || _ctx.loadingTags)
    ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PageHeader, { "header-text": "Create new recipe" }),
        _createVNode(_component_RecipeForm, {
          recipe: _ctx.nRecipe,
          "user-tags": _ctx.tags,
          onStartFlow: _ctx.startFlow,
          onUpdateRecipeData: _ctx.updateRecipeData,
          onAddRecipeImage: _ctx.addRecipeImage
        }, null, 8, ["recipe", "user-tags", "onStartFlow", "onUpdateRecipeData", "onAddRecipeImage"])
      ]))
}