
import { onBeforeMount } from "vue";
import firebase from "firebase";
import store from "@/main";
import { User as RecipebookUser, UserRole } from "@/types/User";
import { Options, Vue } from "vue-class-component";
import { updateLastSeenDate } from "@/components/FirebaseMutations";
import User = firebase.User;

@Options({
  beforeMount() {
    // Function that adds a new user to the database (as we supplement the data)
    async function uploadUserIfNotExists(user: User) {
      // Get a document (if exists)
      let userExists = (
        await firebase
          .firestore()
          .collection(store.state.COLLECTION_USERS)
          .doc(user.uid)
          .get()
      ).exists;

      // Don"t do anything if exists
      if (userExists) return false;

      // Create new user
      const nUser: RecipebookUser = {
        _id: user.uid,
        creationDate: new Date().toISOString(),
        displayName:
          user.displayName !== "" ? user.displayName : store.state.nUsername,
        email: user.email,
        profilePicture: user.photoURL,
        role: UserRole.USER,
        walkthroughComplete: false,
        favouriteRecipes: [],
        lastSeenDate: new Date().toISOString(),
      };
      // Update database with new user
      await firebase
        .firestore()
        .collection(store.state.COLLECTION_USERS)
        .doc(nUser._id)
        .set(nUser)
        .then(() => {
          return true;
        });
    }

    // What happens before the app mounts
    onBeforeMount(() => {
      // Listen when auth state changes
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // Upload user if they don"t exist and after that set a listener on the user snapshot
          updateLastSeenDate(user.uid);
          uploadUserIfNotExists(user).finally(() =>
            firebase
              .firestore()
              .collection(store.state.COLLECTION_USERS)
              .doc(user.uid)
              // Update context when snapshot updates
              .onSnapshot((snapshot) => {
                store.commit("updateContextUser", snapshot.data());
              })
          );
        }
      });

      if (navigator.userAgent.indexOf("Windows") != -1) {
        document.body.classList.add("windows-os");
      }
    });
  },
})
export default class App extends Vue {}
