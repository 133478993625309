
import ProfileProfilePicture from "@/components/profile/ProfileProfilePicture.vue";
import store from "@/main";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { ProfileProfilePicture },
  data() {
    return {
      displayName: store.state.contextUser.displayName,
      profilePicture: store.state.contextUser.profilePicture,
    };
  },
  methods: {
    changeUsername() {
      store.commit("updateWalkthroughUsername", this.displayName);
    },
    onProfilePictureUpdated(url: string) {
      this.profilePicture = url;
    },
  },
})
export default class WalkthroughSlide1 extends Vue {}
