
import BlockProfileFriendList from "@/components/profile/blocks/BlockProfileFriendList.vue";
import store from "@/main";
import {
  getAllUsersExcept,
  getFriendsByUserId,
  getUserById,
} from "@/components/FirebaseQueries";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { Options, Vue } from "vue-class-component";
import { User } from "@/types/User";

@Options({
  components: { LoaderCircle, BlockProfileFriendList },
  emits: ["onFriendMutated", "onFriendRemoved"],
  data() {
    return {
      users: [],
      allUsers: [],
      loading: true,
      searchValue: "",
    };
  },
  async mounted() {
    await this.loadFriends();
    this.loading = false;
  },
  methods: {
    async loadFriends() {
      const friends = await getFriendsByUserId(store.state.contextUser._id);
      getAllUsersExcept([
        store.state.contextUser._id,
        ...friends.map((friend) => friend._id),
      ]).then((users) => {
        this.users = users;
        this.allUsers = users;
      });
    },
    async onFriendAdded(user: User) {
      this.loading = true;
      this.allUsers[this.allUsers.indexOf(user)] = await getUserById(user._id);
      this.loading = false;
    },
    async onFriendRemoved(user: User) {
      this.loading = true;
      this.allUsers.splice(this.allUsers[this.allUsers.indexOf(user)], 1);
      this.loading = false;
    },
    oninputSearchUsers() {
      this.users = [...this.allUsers].filter(
        (it: User) =>
          it.email?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          it.displayName.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    },
  },
})
export default class WalkthroughSlide3 extends Vue {}
