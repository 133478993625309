
import TagsContainer from "@/components/recipe/components/TagsContainer.vue";
import { convertStringToFormattedDate } from "@/components/GlobalFunctions";
import UserIconName from "@/components/common/usercard/UserIconName.vue";
import { getTagsByUserId, getUserById } from "@/components/FirebaseQueries";
import {
  changeRecipeStatusById,
  deleteRecipeByUserId,
} from "@/components/FirebaseMutations";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import {
  DocumentRemoveIcon,
  EyeOffIcon,
  ReplyIcon,
} from "@heroicons/vue/outline";
import { PropType } from "vue";
import { Recipe, RecipeStatus } from "@/types/Recipe";
import { Options, Vue } from "vue-class-component";
import { User } from "@/types/User";
import store from "@/main";

@Options({
  emits: ["onRecipeDeleted"],
  components: {
    LoaderCircle,
    UserIconName,
    TagsContainer,
    ReplyIcon,
    DocumentRemoveIcon,
    EyeOffIcon,
  },
  props: {
    recipe: {
      type: Object as PropType<Recipe>,
      required: true,
    },
    showRestore: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: true,
      recipeAuthorName: String,
      recipeAuthorImage: String,
      creationDateFormatted: "",
      tags: [],
      contextUser: store.state.contextUser,
    };
  },
  async mounted() {
    const allAuthorTags = await getTagsByUserId(this.recipe.authorId);
    if (allAuthorTags) {
      this.tags = [...allAuthorTags].filter((tag) =>
        this.recipe.tags.includes(tag._id)
      );
    }

    await getUserById(this.recipe.authorId).then((user: User) => {
      this.recipeAuthorName = user.displayName;
      this.recipeAuthorImage = user.profilePicture;
      this.creationDateFormatted = convertStringToFormattedDate(
        this.recipe.creationDate
      );
    });
    this.loading = false;
  },
  methods: {
    restore() {
      if (!confirm("Are you sure you want to restore this recipe?")) return;
      changeRecipeStatusById(this.recipe._id, RecipeStatus.PUBLISHED).then(() =>
        this.$router.replace(`/Recipe/${this.recipe._id}`)
      );
    },
    remove() {
      if (!confirm("Are you sure you want to delete this recipe?")) return;
      deleteRecipeByUserId(this.recipe._id).then(() => {
        this.$emit("onRecipeDeleted", this.recipe._id);
      });
    },
  },
})
export default class RecipeBlockCompact extends Vue {}
