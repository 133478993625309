import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c07752dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-bugs-list-view" }
const _hoisted_2 = {
  key: 1,
  class: "container-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_BugsListItem = _resolveComponent("BugsListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bugs, (bug) => {
            return (_openBlock(), _createElementBlock("div", {
              key: bug._id,
              class: "per-bug"
            }, [
              _createVNode(_component_BugsListItem, {
                bug: bug,
                onUpdateBugs: _ctx.updateBugs
              }, null, 8, ["bug", "onUpdateBugs"])
            ]))
          }), 128))
        ]))
  ]))
}