import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "home" }
const _hoisted_3 = { class: "router-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SidePanel = _resolveComponent("SidePanel")!

  return (_ctx.contextUser)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.$route.fullPath !== '/Walkthrough')
            ? (_openBlock(), _createBlock(_component_Navbar, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createBlock(_component_router_view, {
              key: _ctx.$route.fullPath
            })),
            (_ctx.$route.fullPath.includes('/Recipe/'))
              ? (_openBlock(), _createBlock(_component_SidePanel, { key: 0 }))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}