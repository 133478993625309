
import store from "@/main";
import { makeId } from "@/components/GlobalFunctions";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import {
  CheckIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/vue/outline";
import { Options, Vue } from "vue-class-component";
import { Tag } from "@/types/Tag";
import {
  addTagsToUser,
  updateTagName,
  updateTags,
} from "@/components/FirebaseMutations";

@Options({
  components: { LoaderCircle, TrashIcon, PencilIcon, CheckIcon, XCircleIcon },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contextUser: store.state.contextUser,
      newTagName: "",
      errorMessage: undefined,
      loading: false,
      editMode: false,
    };
  },
  methods: {
    addTag(e: HTMLFormElement) {
      e.preventDefault();
      this.loading = true;
      const tag: Tag = {
        _id: makeId(store.state.COLLECTION_TAGS),
        name: this.newTagName,
      };
      this.tags.push(tag);
      addTagsToUser(this.contextUser._id, this.tags)
        .then(() => (this.newTagName = ""))
        .finally(() => (this.loading = false));
    },
    detectDuplicates() {
      if (
        [...this.tags]
          .map((tag) => tag.name.toLowerCase())
          .includes(this.newTagName.toLowerCase())
      ) {
        this.errorMessage = "Tag '" + this.newTagName + "' already exists.";
        (
          document.getElementById("add-tag-submit") as HTMLButtonElement
        ).disabled = true;
        document.getElementById("add-tag-submit")?.classList.add("disabled");
      } else {
        this.errorMessage = undefined;
        (
          document.getElementById("add-tag-submit") as HTMLButtonElement
        ).disabled = false;
        document.getElementById("add-tag-submit")?.classList.remove("disabled");
      }
    },
    removeTag(tag: Tag) {
      if (confirm(`Are you sure you want to remove the tag: ${tag.name}?`)) {
        this.loading = true;
        // Remove tag from local tags-list
        this.tags.splice(this.tags.indexOf(tag), 1);
        updateTags(this.contextUser._id, this.tags).then(
          () => (this.loading = false)
        );
      }
    },
    editTag(tag: Tag) {
      this.editMode = true;
      this.tagToEdit = tag;
      this.edittedTagName = tag.name;
    },
    async saveEditted(tag: Tag) {
      if (
        this.edittedTagName?.length === 0 ||
        this.edittedTagName === tag.name
      ) {
        this.cancelEditTag();
        return;
      }
      await updateTagName(tag, this.edittedTagName);
      this.tags[this.tags?.indexOf(tag)] = {
        _id: tag._id,
        name: this.edittedTagName,
      };
      this.cancelEditTag();
    },
    cancelEditTag() {
      this.edittedTagName = "";
      this.editMode = false;
    },
  },
})
export default class CreateTags extends Vue {
  edittedTagName?: string = "";
  tagToEdit?: Tag;
}
