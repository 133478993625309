
import RecipeTitle from "@/components/recipe/components/RecipeTitle.vue";
import RecipeEstimatedTime from "@/components/recipe/components/RecipeEstimatedTime.vue";
import RecipeTags from "@/components/recipe/components/RecipeTags.vue";
import RecipeImages from "@/components/recipe/components/RecipeImages.vue";
import RecipePrivate from "@/components/recipe/components/RecipePrivate.vue";
import store from "@/main";
import { QuillEditor } from "@vueup/vue-quill";
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";
import { Recipe, RecipeData } from "@/types/Recipe";
import { InformationCircleIcon } from "@heroicons/vue/outline";
import ModalView from "@/components/modal/ModalView.vue";
import RecipeStatus from "@/components/recipe/components/RecipeStatus.vue";
import RecipeServings from "@/components/recipe/components/RecipeServings.vue";

@Options({
  components: {
    RecipeServings,
    RecipeStatus,
    ModalView,
    RecipePrivate,
    RecipeImages,
    RecipeTags,
    RecipeEstimatedTime,
    RecipeTitle,
    QuillEditor,
    InformationCircleIcon,
  },
  emits: ["updateRecipeData"],
  props: {
    recipe: Object as PropType<Recipe>,
    userTags: Array,
    selectedTags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contextUser: store.state.contextUser,
      isModalIngredients: false,
      isModalMethod: false,
    };
  },
  mounted() {
    if (this.recipe.ingredients) {
      document.querySelectorAll(".ql-editor")[0].innerHTML =
        this.recipe.ingredients;
    }
    if (this.recipe.method) {
      document.querySelectorAll(".ql-editor")[1].innerHTML = this.recipe.method;
    }
  },
  methods: {
    startFlow(e: HTMLFormElement) {
      e.preventDefault();
      this.$emit("startFlow");
    },
    updateRecipeIngredients() {
      this.updateRecipeData({
        key: "ingredients",
        value: document.querySelectorAll(".ql-editor")[0].innerHTML,
      });
    },
    updateRecipeMethod() {
      this.updateRecipeData({
        key: "method",
        value: document.querySelectorAll(".ql-editor")[1].innerHTML,
      });
    },
    updateRecipeData(data: RecipeData) {
      this.$emit("updateRecipeData", data);
    },
  },
})
export default class RecipeForm extends Vue {}
