
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["mouseenter", "mouseout"],
  props: ["slide", "currentSlide", "index", "direction"],
  computed: {
    transitionEffect(): string {
      return this.direction === "right" ? "slide-out" : "slide-in";
    },
  },
})
export default class CarouselItem extends Vue {}
