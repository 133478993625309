
import store from "@/main";
import { getTagsByUserId } from "@/components/FirebaseQueries";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { Options, Vue } from "vue-class-component";
import { Tag } from "@/types/Tag";
import { PropType } from "vue";

@Options({
  components: { LoaderCircle },
  emits: ["updatedFilterTags"],
  props: {
    givenTags: {
      type: Array as PropType<string[]>,
      default: [],
    },
  },
  data() {
    return {
      tags: [],
      selectedTags: [],
      loading: true,
    };
  },
  methods: {
    onClickTagChip(e: any, tag: Tag) {
      const target = e.target;
      if (target.classList.contains("active")) {
        target.classList.remove("active");
        this.selectedTags.splice(this.selectedTags.indexOf(tag), 1);
      } else {
        target.classList.add("active");
        this.selectedTags.push(tag);
      }
      this.$emit("updatedFilterTags", this.selectedTags);
    },
  },
  async mounted() {
    this.loading = true;
    this.tags = this.givenTags;
    if (this.tags.length === 0) {
      const tagsByUserId = await getTagsByUserId(store.state.contextUser._id);
      if (tagsByUserId && tagsByUserId.length > 0) {
        this.tags = tagsByUserId.map((tag) => tag.name);
      }
    }
    this.loading = false;
  },
})
export default class RecipeTagsFilter extends Vue {}
