
import firebase from "firebase/app";
import store from "@/main";
import { getTagsByUserId } from "@/components/FirebaseQueries";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import RecipeForm from "@/components/recipe/components/RecipeForm.vue";
import PageHeader from "@/components/common/header/PageHeader.vue";
import { uploadFiles } from "@/components/FirebaseMutations";
import Sidepanel from "@/components/common/sidepanel/SidePanel.vue";
import { Options, Vue } from "vue-class-component";
import { Recipe, RecipeData, RecipeStatus } from "@/types/Recipe";
import eventService from "@/services/EventService";
import firestore = firebase.firestore;
import DocumentReference = firestore.DocumentReference;

@Options({
  components: { Sidepanel, PageHeader, RecipeForm, LoaderCircle },
  data() {
    return {
      contextUser: store.state.contextUser,
      loadingTags: true,
      tags: [],
      loading: false,
    };
  },
  mounted() {
    getTagsByUserId(this.contextUser._id).then((tags) => {
      if (tags) {
        this.tags = tags;
      }
      this.loadingTags = false;
    });
  },
  methods: {
    updateRecipeIngredients() {
      this.updateRecipeData({
        key: "ingredients",
        value: document.querySelectorAll(".ql-editor")[0].innerHTML,
      });
    },
    updateRecipeMethod() {
      this.updateRecipeData({
        key: "method",
        value: document.querySelectorAll(".ql-editor")[1].innerHTML,
      });
    },
    updateRecipeData(data: RecipeData) {
      this.nRecipe[data.key] = data.value;
      if (data.key === "status") {
        console.log("New Status: " + this.nRecipe.status);
      }
    },
    uploadRecipe(docRef: DocumentReference) {
      docRef.set(this.nRecipe).then(() => this.addToEvents());
    },
    addRecipeImage(blobs: { file: Blob[]; index: number }[]) {
      this.imagesToAddByBlob = blobs
        .sort((a, b) => a.index - b.index)
        .map((blob) => blob.file);
    },
    async startFlow() {
      this.loading = true;

      const docRef = firebase
        .firestore()
        .collection(store.state.COLLECTION_RECIPES)
        .doc();

      this.nRecipe.creationDate = new Date().toISOString();
      this.nRecipe.authorId = this.contextUser._id;
      this.nRecipe._id = docRef.id;

      this.nRecipe.images = await uploadFiles(
        this.nRecipe._id,
        this.imagesToAddByBlob
      );

      this.uploadRecipe(docRef);
    },
    addToEvents() {
      if (
        !this.nRecipe.isPrivate &&
        this.nRecipe.status === RecipeStatus.PUBLISHED
      ) {
        eventService
          .addEventRecipeAdded(this.nRecipe)
          .then(() => this.$router.push(`/Recipe/${this.nRecipe._id}`));
      } else {
        this.$router.push(`/Recipe/${this.nRecipe._id}`);
      }
    },
  },
})
export default class AddRecipe extends Vue {
  imagesToAddByBlob: Blob[] = [];
  nRecipe: Recipe = {
    _id: "",
    authorId: "",
    creationDate: "",
    title: "",
    tags: [],
    comments: [],
    isPrivate: false,
    status: RecipeStatus.PUBLISHED,
    publishDate: "",
    updatedDate: "",
    prepTime: 0,
    cookTime: 0,
    servings: 0,
  };
}
