
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["updateRecipeData"],
  props: {
    recipeTitle: {
      type: String,
      default: "",
    },
  },
  beforeMount() {
    this.nTitle = this.recipeTitle;
  },
  methods: {
    updateParent() {
      this.$emit("updateRecipeData", { key: "title", value: this.nTitle });
    },
  },
})
export default class RecipeTitle extends Vue {
  nTitle!: string;
}
