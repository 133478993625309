import firebase from "firebase/app";
import store from "@/main";
import {
  getAcceptedPendingFriendsListByUserId,
  getAllMyFavouriteRecipes,
  getAllUsersByRole,
  getRecipeById,
  getRecipesByUserId,
  getTagsByUserId,
} from "@/components/FirebaseQueries";
import { Tag } from "@/types/Tag";
import {
  Recipe,
  RecipeImage,
  RecipesByTag,
  RecipeStatus,
} from "@/types/Recipe";
import {
  FriendFormAccepted,
  FriendFormAcceptedPending,
  FriendFormRequest,
} from "@/types/FriendForm";
import { FriendStatus, User, UserRole } from "@/types/User";
import { Event, EventData, EventType } from "@/types/Event";
import { Bug, BugStatus } from "@/types/Bug";
import { collectionTags, collectionUsers } from "@/components/Constants";
import { makeId, resizeImage } from "@/components/GlobalFunctions";
import Blob from "buffer";

type UpdateTags = (userId: string, tags: Tag[]) => Promise<boolean>;
export const updateTags: UpdateTags = (userId, tags) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_TAGS)
    .doc(userId)
    .update({
      tags: tags,
    })
    .then(() => true)
    .catch(() => false);
};

type UpdateRecipe = (nRecipe: Recipe) => Promise<boolean>;
export const updateRecipe: UpdateRecipe = (nRecipe) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_RECIPES)
    .doc(nRecipe._id)
    .update(nRecipe)
    .then(() => true)
    .catch(() => false);
};

type ReplaceRecipe = (nRecipe: Recipe) => Promise<boolean>;
export const replaceRecipe: ReplaceRecipe = (nRecipe) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_RECIPES)
    .doc(nRecipe._id)
    .set(nRecipe)
    .then(() => true)
    .catch(() => false);
};

type AddFriendRequest = (request: FriendFormRequest) => Promise<boolean>;
export const addFriendRequest: AddFriendRequest = (request) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_FRIENDS)
    .doc(request.userToBefriend)
    .set(
      { pending: firebase.firestore.FieldValue.arrayUnion(request) },
      { merge: true }
    )
    .then(() => {
      return firebase
        .firestore()
        .collection(store.state.COLLECTION_FRIENDS)
        .doc(request.requester)
        .set(
          { pending: firebase.firestore.FieldValue.arrayUnion(request) },
          { merge: true }
        )
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    })
    .catch(() => {
      return false;
    });
};

type SendFriendRequest = (userToBefriend: User) => Promise<boolean>;
export const sendFriendRequest: SendFriendRequest = (userToBefriend) => {
  const request: FriendFormRequest = {
    creationDate: new Date().toISOString(),
    requester: store.state.contextUser._id,
    userToBefriend: userToBefriend._id,
    status: FriendStatus.PENDING,
    latestStatusUpdate: new Date().toISOString(),
  };
  return addFriendRequest(request)
    .then(() => true)
    .catch(() => false);
};

type RejectFriendRequest = (request: FriendFormRequest) => Promise<boolean>;
export const rejectFriendRequest: RejectFriendRequest = (request) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_FRIENDS)
    .doc(request.userToBefriend)
    .update({ pending: firebase.firestore.FieldValue.arrayRemove(request) })
    .then(() => {
      return firebase
        .firestore()
        .collection(store.state.COLLECTION_FRIENDS)
        .doc(request.requester)
        .update({
          pending: firebase.firestore.FieldValue.arrayRemove(request),
        })
        .then(() => true)
        .catch(() => false);
    })
    .catch(() => false);
};

type RemoveFriend = (userId: string) => Promise<boolean>;
export const removeFriend: RemoveFriend = (userId) => {
  const meId = store.state.contextUser._id;
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_FRIENDS)
    .doc(userId)
    .get()
    .then((snapshot) => {
      // remove me
      const acceptedList = snapshot
        .get("accepted")
        .filter((friend: FriendFormAccepted) => friend.friendId !== meId);
      // Update with new list
      return snapshot.ref
        .update({ accepted: acceptedList })
        .then(() => {
          return firebase
            .firestore()
            .collection(store.state.COLLECTION_FRIENDS)
            .doc(meId)
            .get()
            .then((snapshot) => {
              // remove user
              const acceptedList = snapshot
                .get("accepted")
                .filter(
                  (friend: FriendFormAccepted) => friend.friendId !== userId
                );
              // update me
              return snapshot.ref
                .update({ accepted: acceptedList })
                .then(() => true)
                .catch(() => false);
            });
        })
        .catch(() => false);
    })
    .catch(() => false);
};

type AcceptFriendRequest = (response: FriendFormAccepted) => Promise<boolean>;
export const acceptFriendRequest: AcceptFriendRequest = (response) => {
  // Add response to current user
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_FRIENDS)
    .doc(store.state.contextUser._id)
    .set(
      { accepted: firebase.firestore.FieldValue.arrayUnion(response) },
      { merge: true }
    )
    .then(() => {
      const userToBefriend = response.friendId;
      response.friendId = store.state.contextUser._id;
      // Add response to other user
      return firebase
        .firestore()
        .collection(store.state.COLLECTION_FRIENDS)
        .doc(userToBefriend)
        .set(
          { accepted: firebase.firestore.FieldValue.arrayUnion(response) },
          { merge: true }
        )
        .then(() => true)
        .catch(() => false);
    })
    .catch(() => false);
};

type DeleteAndAcceptFriendRequest = (
  request: FriendFormRequest,
  friendId: string
) => Promise<boolean>;
export const deleteAndAcceptFriendRequest: DeleteAndAcceptFriendRequest = (
  request,
  friendId
) => {
  const response: FriendFormAccepted = {
    friendId: friendId,
    request: request,
    acceptedDate: new Date().toISOString(),
    status: FriendStatus.ACCEPTED,
    acceptedBy: store.state.contextUser._id,
  };
  return rejectFriendRequest(request)
    .then(() =>
      acceptFriendRequest(response)
        .then(() => true)
        .catch(() => false)
    )
    .catch(() => false);
};

type ChangeRecipeStatusById = (
  recipeId: string,
  status: RecipeStatus
) => Promise<boolean>;
export const changeRecipeStatusById: ChangeRecipeStatusById = async (
  recipeId,
  status
) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_RECIPES)
    .doc(recipeId)
    .update({ status: status })
    .then(() => true)
    .catch(() => false);
};

type AddEvent = (type: EventType, data?: EventData) => Promise<boolean>;
export const addEvent: AddEvent = async (type, data) => {
  const userId = store.state.contextUser._id;
  const acceptedFriends: FriendFormAcceptedPending =
    await getAcceptedPendingFriendsListByUserId(store.state.contextUser._id);
  let releventUsers: string[] = [];

  switch (type) {
    case EventType.ADDED_COMMENT:
      // TODO: Fix this
      // eslint-disable-next-line no-case-declarations
      if (acceptedFriends?.accepted && acceptedFriends.accepted.length > 0) {
        const commentedOnFriend = [...acceptedFriends.accepted].find(
          (friend: FriendFormAccepted) => friend.friendId === data?.authorId
        )?.friendId;
        if (commentedOnFriend) {
          releventUsers = [commentedOnFriend];
        }
      }
      break;
    case EventType.ADDED_RECIPE:
      if (acceptedFriends?.accepted && acceptedFriends.accepted.length > 0) {
        releventUsers = [...acceptedFriends.accepted].map(
          (friend) => friend.friendId
        );
      }
      break;
    case EventType.ADDED_BUG:
      // eslint-disable-next-line no-case-declarations
      const users = await getAllUsersByRole(UserRole.ADMIN);
      if (users) {
        releventUsers = users.map((user) => user._id);
      }
      break;
    case EventType.SENT_FRIEND_REQUEST:
      if (data?.friendRequestSentTo) {
        releventUsers = [data.friendRequestSentTo];
      }
      break;
    case EventType.ADDED_FRIEND:
      if (data?.friendRequestSentTo) {
        releventUsers = [data.friendRequestSentTo, store.state.contextUser._id];
      }
      break;
    default:
      releventUsers = [];
      break;
  }

  // Create doc reference
  const documentReference = firebase
    .firestore()
    .collection(store.state.COLLECTION_EVENTS)
    .doc();

  // Create event
  const event: Event = {
    _id: documentReference.id,
    type: type,
    date: new Date().toISOString(),
    data: data,
    userId: userId,
    includedFriends: releventUsers,
  };

  // Upload event
  return (
    documentReference
      .set(event)
      // Upload users events field with new event ID
      .then(() => true)
      .catch(() => false)
  );
};

type CompleteMyWalkthrough = () => Promise<boolean> | void;
export const completeMyWalkthrough: CompleteMyWalkthrough = () => {
  if (
    !store.state.contextUser.displayName ||
    store.state.contextUser.displayName.length === 0
  ) {
    alert("Please go back and enter a username");
    return;
  }

  const nUser = store.state.contextUser;
  nUser.walkthroughComplete = true;
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_USERS)
    .doc(nUser._id)
    .update({
      walkthroughComplete: nUser.walkthroughComplete,
    })
    .then(() => {
      store.commit("updateContextUser", nUser);
      return true;
    })
    .catch(() => false);
};

type UpdateUsername = (username: string) => Promise<boolean>;
export const updateUsername: UpdateUsername = (username) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_USERS)
    .doc(store.state.contextUser._id)
    .update({
      displayName: username,
    })
    .then(() => true)
    .catch(() => false);
};

type AddRecipeBatch = (recipes: Recipe[]) => Promise<boolean>;
export const addRecipeBatch: AddRecipeBatch = (recipes) => {
  const batch = firebase.firestore().batch();
  recipes.forEach((recipe) => {
    const ref = firebase
      .firestore()
      .collection(store.state.COLLECTION_RECIPES)
      .doc();
    batch.set(ref, recipe);
  });
  return batch.commit().then(() => true);
};

type SetIsPrivateField = () => Promise<boolean>;
export const setIsPrivateField: SetIsPrivateField = async () => {
  const batch = firebase.firestore().batch();
  const docRefs = await firebase
    .firestore()
    .collection(store.state.COLLECTION_RECIPES)
    .get();
  docRefs.forEach((docRef) => {
    batch.update(docRef.ref, { isPrivate: false });
  });
  return batch
    .commit()
    .then(() => true)
    .catch(() => false);
};

type CreateBugReport = (title: string, text: string) => Promise<boolean>;
export const createBugReport: CreateBugReport = (title, text) => {
  const ref = firebase
    .firestore()
    .collection(store.state.COLLECTION_BUGS)
    .doc();
  return ref
    .set({
      _id: ref.id,
      title: title,
      description: text,
      reporter: store.state.contextUser._id,
      reportDate: new Date().toISOString(),
      status: BugStatus.OPEN,
      githubLink: "",
      devComment: "",
    })
    .then(() => true)
    .catch(() => false);
};

type UpdateBug = (bug: Bug) => Promise<boolean>;
export const updateBug: UpdateBug = (bug) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_BUGS)
    .doc(bug._id)
    .update(bug)
    .then(() => true)
    .catch(() => false);
};
/**
 * {
 *       description: bug.description,
 *       devComment: bug.devComment,
 *       githubLink: bug.githubLink,
 *       reportDate: bug.reportDate,
 *       reporter: bug.reporter,
 *       status: bug.status,
 *       title: bug.title,
 *     }
 */

type GetRecipesByTag = (
  userId: string,
  includeFavourites: boolean,
  hidePrivate: boolean
) => Promise<RecipesByTag[]>;
export const getRecipesByTag: GetRecipesByTag = async (
  userId,
  includeFavourites,
  hidePrivate
) => {
  const recipesByTag: RecipesByTag[] = [];
  const tags = await getTagsByUserId(userId);
  const recipes = await getRecipesByUserId(
    userId,
    RecipeStatus.PUBLISHED,
    hidePrivate
  );
  const favouriteRecipes = await getAllMyFavouriteRecipes();
  // Adding recipes without tags
  if (includeFavourites) {
    recipesByTag.push({
      tag: "Favourites",
      recipes: favouriteRecipes,
    });
  }
  recipesByTag.push({
    tag: "Tag-less recipes",
    recipes: [...recipes].filter(
      (recipe) => recipe.tags === undefined || recipe.tags.length === 0
    ),
  });
  if (tags) {
    tags.sort((a, b) => a.name.localeCompare(b.name));
    tags.forEach((tag) => {
      recipesByTag.push({
        tag: tag.name,
        recipes: [...recipes].filter((recipe) => recipe.tags.includes(tag._id)),
      });
    });
  }
  return recipesByTag;
};

type RemoveAllImages = (
  recipeId: string,
  imageNames: string[]
) => Promise<boolean>;
export const removeAllImages: RemoveAllImages = async (
  recipeId,
  imageNames
) => {
  if (imageNames.length > 0) {
    for (const image of imageNames) {
      if (!image) {
        continue;
      }
      await firebase
        .storage()
        .ref(store.state.COLLECTION_RECIPES)
        .child(recipeId)
        .child(image)
        .delete();
    }
    return true;
  } else {
    return true;
  }
};

type DeleteRecipeByUserId = (recipeId: string) => Promise<boolean>;
export const deleteRecipeByUserId: DeleteRecipeByUserId = async (recipeId) => {
  const recipe = await getRecipeById(recipeId);
  await removeAllImages(
    recipeId,
    recipe.images?.map((image) => image.name) || []
  );
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_RECIPES)
    .doc(recipeId)
    .delete()
    .then(() => true)
    .catch(() => false);
};

type FavouriteRecipe = (recipeId: string, authorId: string) => Promise<boolean>;
export const favouriteRecipe: FavouriteRecipe = (recipeId, authorId) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_USERS)
    .doc(store.state.contextUser._id)
    .update(
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        favouriteRecipes: firebase.firestore.FieldValue.arrayUnion({
          recipeId: recipeId,
          authorId: authorId,
        }),
      },
      { merge: true }
    )
    .then(() => true)
    .catch(() => false);
};

type UnFavouriteRecipe = (
  recipeId: string,
  authorId: string
) => Promise<boolean>;
export const unFavouriteRecipe: UnFavouriteRecipe = (recipeId, authorId) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_USERS)
    .doc(store.state.contextUser._id)
    .update(
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        favouriteRecipes: firebase.firestore.FieldValue.arrayRemove({
          recipeId: recipeId,
          authorId: authorId,
        }),
      },
      { merge: true }
    )
    .then(() => true)
    .catch(() => false);
};

type UpdateLastSeenDate = (uid: string) => Promise<boolean>;
export const updateLastSeenDate: UpdateLastSeenDate = (uid) => {
  return collectionUsers()
    .doc(uid)
    .update({
      lastSeenDate: new Date().toISOString(),
    })
    .then(() => true)
    .catch(() => false);
};

type UpdateTagName = (oldTag: Tag, nName: string) => Promise<boolean>;
export const updateTagName: UpdateTagName = async (oldTag, nName) => {
  const docRef = collectionTags().doc(store.state.contextUser._id);
  await docRef.update({
    tags: firebase.firestore.FieldValue.arrayUnion({
      _id: oldTag._id,
      name: nName,
    }),
  });
  await docRef.update({
    tags: firebase.firestore.FieldValue.arrayRemove(oldTag),
  });
  return true;
};

type UploadFiles = (
  recipeId: string,
  blobs: Blob[],
  startIndex?: number
) => Promise<RecipeImage[]>;
export const uploadFiles: UploadFiles = async (
  recipeId,
  blobs,
  startIndex = 0
) => {
  if (!blobs || blobs.length === 0) {
    return [];
  }
  const newImages: RecipeImage[] = [];
  // Create doc ref to recipe id folder
  const ref = firebase
    .storage()
    .ref(`${store.state.COLLECTION_RECIPES}/${recipeId}/`);
  let i = startIndex;
  // Loop through files
  for (const image_blob of blobs as Blob[]) {
    const resizedImage = await resizeImage({
      file: image_blob as File,
      maxSize: 2000,
    });
    // Id
    const id = makeId(store.state.COLLECTION_RECIPES);
    // Task
    const uploadTask = await ref.child(id).put(resizedImage as Blob);
    // Url
    const downloadUrl = await uploadTask.ref.getDownloadURL();
    newImages.push({
      index: i,
      name: id,
      url: downloadUrl,
    });
    i++;
  }
  return newImages;
};

type AddTagsToUser = (userid: string, tags: Tag[]) => Promise<boolean>;
export const addTagsToUser: AddTagsToUser = (userId, tags) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_TAGS)
    .doc(userId)
    .set(
      {
        userId: userId,
        tags: tags,
      },
      { merge: true }
    )
    .then(() => true)
    .catch(() => false);
};

type UpdateRecipeComments = (recipe: Recipe) => Promise<Recipe>;
export const updateRecipeComments: UpdateRecipeComments = (recipe) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_RECIPES)
    .doc(recipe._id)
    .update("comments", recipe.comments)
    .then(() => recipe)
    .catch(() => recipe);
};

type DeleteCommentById = (
  recipeId: string,
  commentId: string
) => Promise<Recipe>;
export const deleteCommentById: DeleteCommentById = async (
  recipeId,
  commentId
) => {
  const recipe: Recipe = await getRecipeById(recipeId);
  recipe.comments = recipe.comments.filter((it) => it._id !== commentId);

  return updateRecipeComments(recipe);
};
