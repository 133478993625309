import { createApp } from "vue";
import { createStore } from "vuex";
import App from "./App.vue";
import router from "@/router";
import firebase from "firebase/app";
import { User, UserRole } from "@/types/User";
import { updateUsername } from "@/components/FirebaseMutations";
import { DefaultReturnPath } from "@/components/Constants";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const user: User | undefined = undefined;

const state = {
  contextUser: user,
  COLLECTION_USERS: process.env.VUE_APP_COLLECTION_USERS,
  COLLECTION_RECIPES: process.env.VUE_APP_COLLECTION_RECIPES,
  COLLECTION_EVENTS: process.env.VUE_APP_COLLECTION_EVENTS,
  COLLECTION_FRIENDS: process.env.VUE_APP_COLLECTION_FRIENDS,
  COLLECTION_TAGS: process.env.VUE_APP_COLLECTION_TAGS,
  COLLECTION_BUGS: process.env.VUE_APP_COLLECTION_BUGS,
  nUsername: "",
  isAdmin: undefined,
};

const store = createStore({
  state() {
    return state;
  },
  mutations: {
    updateWalkthroughUsername(state: any, username) {
      state.nUsername = username;
      state.contextUser.displayName = username;
      updateUsername(username);
    },
    updateContextUser(state, nUser: User) {
      console.log(nUser._id);
      state.contextUser = nUser;
      state.isAdmin = nUser.role === UserRole.ADMIN;
      if (state.contextUser.walkthroughComplete === true) {
        const routerPath = router.currentRoute.value.fullPath.toLowerCase();
        if (["/login", "/walkthrough"].includes(routerPath) && nUser)
          router.push(DefaultReturnPath);
      } else {
        router.replace("/Walkthrough");
      }
    },
  },
});

firebase.initializeApp(firebaseConfig);
const Vue = createApp(App);
// Vue.component("QuillEditor", VueQuill.QuillEditor);
Vue.use(store);
Vue.use(router).mount("#app");

export default store;
