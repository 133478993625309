import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b6c288f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "walkthrough-slide-3-container" }
const _hoisted_2 = {
  key: 1,
  class: "friends-list-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_BlockProfileFriendList = _resolveComponent("BlockProfileFriendList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "search-bar",
      type: "text",
      maxlength: "30",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.oninputSearchUsers && _ctx.oninputSearchUsers(...args))),
      placeholder: "Search username or email"
    }, null, 544), [
      [_vModelText, _ctx.searchValue]
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createBlock(_component_BlockProfileFriendList, {
            key: _ctx.users,
            linkable: false,
            "horizontal-slide": true,
            onOnFriendMutated: _ctx.onFriendAdded,
            onOnFriendRemoved: _ctx.onFriendRemoved,
            friends: _ctx.users
          }, null, 8, ["onOnFriendMutated", "onOnFriendRemoved", "friends"]))
        ]))
  ]))
}