
import FriendsListRow from "@/components/profile/friends/AddFriendsListRow.vue";
import { Options, Vue } from "vue-class-component";
import { User } from "@/types/User";

@Options({
  components: { FriendsListRow },
  props: {
    users: Array,
    mode: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedTrack: [],
    };
  },
  methods: {
    onCheckboxSelected(selectedUser: User) {
      // if (selectedUser.isSelected) {
      //   this.selectedTrack.push(selectedUser._id);
      // } else {
      this.selectedTrack.splice(
        this.selectedTrack.indexOf(selectedUser._id),
        1
      );
      // }
      this.$emit("onCheckboxSelected", this.selectedTrack);
    },
  },
})
export default class AddFriendsList extends Vue {}
