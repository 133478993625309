
import firebase from "firebase/app";

export default {
  name: "GoogleLogin",
  methods: {
    socialLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });

      firebase.auth().signInWithPopup(provider);
    },
  },
};
