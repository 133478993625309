
import NavigationDots from "@/components/walkthrough/NavigationDots.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { NavigationDots },
  emits: ["updateIndex"],
  props: {
    title: {
      type: String,
      required: true,
    },
    subText: String,
    index: {
      type: Number,
      required: true,
    },
    buttonNextLabel: {
      type: String,
      default: "Next",
    },
  },
  data() {
    return {
      navDisabled: true,
    };
  },
  methods: {
    updateIndex(index: number) {
      this.$emit("updateIndex", index);
    },
  },
})
export default class WalkthroughSlideTemplate extends Vue {}
