import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78588ec2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "wrapper-filter-recipe-on-tags"
}
const _hoisted_2 = {
  key: 0,
  class: "tag-chips"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.tags.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags.sort((a, b) => (a > b ? 1 : -1)), (tag) => {
                return (_openBlock(), _createElementBlock("div", { key: tag }, [
                  (tag !== 'Favourites' && tag !== 'Tag-less recipes')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "tag-chips--chip",
                        onClick: ($event: any) => (_ctx.onClickTagChip($event, tag))
                      }, _toDisplayString(tag), 9, _hoisted_3))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
}