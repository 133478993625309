
import AddFriendsList from "@/components/profile/friends/AddFriendsList.vue";
import store from "@/main";
import {
  getAcceptedPendingFriendsListByUserId,
  getAllUsersExcept,
} from "@/components/FirebaseQueries";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { Options, Vue } from "vue-class-component";
import { User } from "@/types/User";

@Options({
  components: { LoaderCircle, AddFriendsList },
  data() {
    return {
      contextUser: store.state.contextUser,
      users: [],
      allUsers: [],
      loading: true,
      searchValue: "",
    };
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      this.$emit("onRequestSent");
      this.loading = true;
      getAcceptedPendingFriendsListByUserId(this.contextUser._id).then(
        (friendsList) => {
          const blacklistedUserIds =
            (friendsList && friendsList.pending.length > 0) ||
            friendsList.accepted.length > 0
              ? [
                  ...friendsList.pending.map(
                    (request) => request.userToBefriend
                  ),
                  ...friendsList.pending.map((request) => request.requester),
                  ...friendsList.accepted.map((response) => response.friendId),
                ].filter(
                  (entry) =>
                    entry !== undefined &&
                    entry !== null &&
                    entry !== this.contextUser._id
                )
              : [];
          getAllUsersExcept([...blacklistedUserIds, this.contextUser._id]).then(
            (users) => {
              this.users = users;
              this.allUsers = users;
              this.loading = false;
            }
          );
        }
      );
    },
    oninputSearchUsers() {
      this.users = [...this.allUsers].filter(
        (it: User) =>
          it.email?.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          it.displayName.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    },
  },
})
export default class AddFriendsModalContent extends Vue {}
