import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a169560"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-navigation-dots" }
const _hoisted_2 = { class: "container-dots" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.count, (num) => {
        return (_openBlock(), _createElementBlock("div", {
          key: num,
          class: _normalizeClass(["dot", num === $props.index + 1 ? 'active' : '']),
          onClick: ($event: any) => (_ctx.$emit('dotClicked', num - 1))
        }, null, 10, _hoisted_3))
      }), 128))
    ])
  ]))
}