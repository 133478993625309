
import EventListItem from "@/components/timeline/event/EventListItem.vue";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { getAllMyEvents } from "@/components/FirebaseQueries";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { LoaderCircle, EventListItem },
  data() {
    return {
      loading: true,
      events: [],
    };
  },
  async mounted() {
    this.events = await getAllMyEvents();
    this.loading = false;
  },
})
export default class EventsList extends Vue {}
