
import { ChevronRightIcon } from "@heroicons/vue/outline";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { ChevronRightIcon },
  props: {
    headerText: String,
    width: {
      default: 325,
      type: Number,
    },
    maxHeight: {
      default: 200,
      type: Number,
    },
  },
  data() {
    return {
      stateIsShowContent: false,
    };
  },
  methods: {
    toggleContent() {
      this.stateIsShowContent = !this.stateIsShowContent;
      this.rotateIcon();
    },
    rotateIcon() {
      const iconElement = document.getElementById(this.headerText);
      iconElement?.classList.contains("rotate")
        ? iconElement.classList.remove("rotate")
        : iconElement?.classList.add("rotate");
    },
  },
})
export default class DropDown extends Vue {}
