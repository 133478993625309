
import Navbar from "@/components/navbar/NavBar.vue";
import store from "@/main";
import firebase from "firebase/app";
import router from "@/router";
import { getUserById } from "@/components/FirebaseQueries";
import { Options, Vue } from "vue-class-component";
import { DefaultReturnPath } from "@/components/Constants";
import SidePanel from "@/components/common/sidepanel/SidePanel.vue";

@Options({
  data() {
    return {
      contextUser: store.state.contextUser,
    };
  },
  components: {
    SidePanel,
    Navbar,
  },
  mounted() {
    if (this.$route.path === "/") {
      this.$router.replace(DefaultReturnPath);
    }
    firebase.auth().onAuthStateChanged((user) => {
      // If user is invalid we send them to login page
      if (!user) {
        router.replace("/Login");
      } else if (user) {
        getUserById(user.uid).then((fbUser) => {
          this.contextUser = fbUser;
          store.commit("updateContextUser", fbUser);
        });
      }
    });
  },
})
export default class RecipeBook extends Vue {}
