
export default {
  name: "UserIconName",
  props: {
    profilePicture: String,
    name: String,
    imageDimensions: {
      default: 50,
      type: Number,
    },
    imageMarginRight: {
      default: 20,
      type: Number,
    },
    fontSize: {
      default: 1,
      type: Number,
    },
    padding: {
      default: 10,
      type: Number,
    },
    hideBackgroundColor: {
      default: false,
      type: Boolean,
    },
  },
};
