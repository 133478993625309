
import CreateTags from "@/components/tags/CreateTags.vue";
import store from "@/main";
import { getTagsByUserId } from "@/components/FirebaseQueries";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { LoaderCircle, CreateTags },
  data() {
    return {
      tags: [],
      loading: true,
    };
  },
  mounted() {
    getTagsByUserId(store.state.contextUser._id)
      .then((tags) => (this.tags = tags))
      .finally(() => (this.loading = false));
  },
})
export default class WalkthroughSlide2 extends Vue {}
