import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wrapper-pending-friends"
}
const _hoisted_2 = { class: "pending-friends-header" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "container-pending-friends" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FriendsListRow = _resolveComponent("FriendsListRow")!

  return (_ctx.requestUsers.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.from)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_3, "Awaiting your reponse"))
            : (_openBlock(), _createElementBlock("h3", _hoisted_4, "Your sent friend-requests"))
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requestUsers, (request, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "per-pending-friend"
            }, [
              _createVNode(_component_FriendsListRow, {
                onOnFriendMutated: _ctx.onFriendMutated,
                user: request.user,
                mode: _ctx.from ? 1 : 2,
                request: request.request
              }, null, 8, ["onOnFriendMutated", "user", "mode", "request"])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}