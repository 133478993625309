export enum EventType {
  ADDED_COMMENT = "ADDED_COMMENT",
  ADDED_RECIPE = "ADDED_RECIPE",
  ADDED_BUG = "ADDED_BUG",
  ADDED_FRIEND = "ADDED_FRIEND",
  SENT_FRIEND_REQUEST = "SENT_FRIEND_REQUEST",
  DEMO = "DEMO",
}

export interface EventData {
  authorId?: string;
  comment?: string;
  linkUrl?: string;
  photoUrl?: string;
  recipeId?: string;
  recipeName?: string;
  bugTitle?: string;
  friendRequestSentTo?: string;
}

export interface Event {
  _id: string;
  data?: EventData;
  includedFriends: string[];
  type: EventType;
  userId: string;
  date: string;
}
