import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fdbfd80a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carousel" }
const _hoisted_2 = { class: "carousel-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_carousel_indicators = _resolveComponent("carousel-indicators")!
  const _component_carousel_item = _resolveComponent("carousel-item")!
  const _component_carousel_controls = _resolveComponent("carousel-controls")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.indicators)
        ? (_openBlock(), _createBlock(_component_carousel_indicators, {
            key: 0,
            total: _ctx.slides.length,
            "current-index": _ctx.currentSlide,
            onSwitch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchSlide($event)))
          }, null, 8, ["total", "current-index"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
        return (_openBlock(), _createBlock(_component_carousel_item, {
          slide: slide.url,
          key: `item-${index}`,
          "current-slide": _ctx.currentSlide,
          index: index,
          direction: _ctx.direction,
          onMouseenter: _ctx.stopSlideTimer,
          onMouseout: _ctx.startSlideTimer
        }, null, 8, ["slide", "current-slide", "index", "direction", "onMouseenter", "onMouseout"]))
      }), 128)),
      (_ctx.controls)
        ? (_openBlock(), _createBlock(_component_carousel_controls, {
            key: 1,
            onPrev: _ctx.prev,
            onNext: _ctx.next
          }, null, 8, ["onPrev", "onNext"]))
        : _createCommentVNode("", true)
    ])
  ]))
}