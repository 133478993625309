import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_BlockProfileFriend = _resolveComponent("BlockProfileFriend")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(`wrapper-block-profile-friend-list ${
      _ctx.horizontalSlide ? 'horizontal-slide' : ''
    } ${_ctx.verticalSlide ? 'vertical-slide' : ''}`)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.friends, (friend, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "per-friend"
          }, [
            _createVNode(_component_BlockProfileFriend, {
              "view-only": _ctx.viewOnly,
              onOnFriendRemoved: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onFriendRemoved'))),
              onOnFriendMutated: _ctx.onFriendMutated,
              user: friend
            }, null, 8, ["view-only", "onOnFriendMutated", "user"])
          ]))
        }), 128))
      ], 2))
}