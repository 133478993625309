import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0be21d56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-sidepanel" }
const _hoisted_2 = { class: "container-button" }
const _hoisted_3 = {
  key: 0,
  class: "container-content"
}
const _hoisted_4 = { class: "container-measurements" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon")!
  const _component_Measurements = _resolveComponent("Measurements")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "container-sidepanel",
      style: _normalizeStyle({ width: _ctx.contentWidth })
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "container-chevron",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)))
        }, [
          _createVNode(_component_ChevronLeftIcon, {
            class: _normalizeClass({ 'icon-active': _ctx.isOpen })
          }, null, 8, ["class"])
        ])
      ]),
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          (_ctx.isOpen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Measurements)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ], 4)
  ]))
}