
import { PlusIcon, TrashIcon } from "@heroicons/vue/outline";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";
import { RecipeImage } from "@/types/Recipe";
import draggable from "vuedraggable";

@Options({
  components: { LoaderCircle, PlusIcon, TrashIcon, draggable },
  emits: ["addRecipeImage", "removeRecipeImage"],
  props: {
    existingImages: {
      type: Array,
      default: () => [] as PropType<RecipeImage[]>,
    },
  },
  beforeMount() {
    this.images = this.existingImages?.sort(
      (a: RecipeImage, b: RecipeImage) => a.index - b.index
    );
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    openInput() {
      document.getElementById("hiddenInputPictures")?.click();
    },
    addRecipeImage() {
      this.$emit(
        "addRecipeImage",
        [...this.recipeImagesToAdd].map((image, i) => {
          return {
            file: image.file,
            index: i,
          };
        })
      );
    },
    handleUploadImages(event: any) {
      if (event.target.files.length >= 5) {
        alert("You can currently only upload a maximum of 4 images");
        return;
      }
      this.loading = true;
      for (const element of event.target.files) {
        const newImage: { file: File; url: string } = {
          file: element,
          url: (window.URL || window.webkitURL).createObjectURL(element),
        };
        this.recipeImagesToAdd.push(newImage);
        this.images = [...this.images, newImage];
      }
      this.addRecipeImage();
      this.loading = false;
    },
    deleteImage(index: RecipeImage) {
      const indexOfDeleted = this.images.indexOf(index);
      const url = this.images.splice(indexOfDeleted, 1);
      this.$emit("removeRecipeImage", url[0]);
      // Check if deleted image is a newly uploaded one
      if (indexOfDeleted >= this.existingImages?.length) {
        // Remove and update parent
        this.recipeImagesToAdd.splice(
          index + this.existingImages?.length - 1,
          1
        );
        this.addRecipeImage();
      }
    },
    computeIndices() {
      this.images.forEach((image: RecipeImage, index: number) => {
        image.index = index;
      });
      this.addRecipeImage();
    },
  },
})
export default class RecipeImages extends Vue {
  images: RecipeImage[] = [];
  recipeImagesToAdd: { file: File; url: string }[] = [];
  drag = false;
}
