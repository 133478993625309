import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "carousel-control left",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('prev')))
    }, _toDisplayString($data.left), 1),
    _createElementVNode("button", {
      class: "carousel-control right",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('next')))
    }, _toDisplayString($data.right), 1)
  ], 64))
}