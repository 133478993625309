
import BlockProfileFriend from "@/components/profile/blocks/BlockProfileFriend.vue";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { Options, Vue } from "vue-class-component";
import { User } from "@/types/User";

@Options({
  components: { LoaderCircle, BlockProfileFriend },
  emits: ["onFriendMutated", "onFriendRemoved"],
  props: {
    friends: Array,
    horizontalSlide: {
      type: Boolean,
      default: false,
    },
    verticalSlide: {
      type: Boolean,
      default: false,
    },
    linkable: {
      type: Boolean,
      default: true,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    onFriendMutated(user: User) {
      this.$emit("onFriendMutated", user);
    },
  },
})
export default class BlockProfileFriendList extends Vue {}
