
import store from "@/main";
import {
  BookOpenIcon,
  ClockIcon,
  CogIcon,
  DocumentReportIcon,
  MenuIcon,
  UserCircleIcon,
} from "@heroicons/vue/outline";
import Modal from "@/components/modal/ModalView.vue";
import BugReportModalContent from "@/components/bugs/BugReportModalContent.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    BugReportModalContent,
    Modal,
    CogIcon,
    BookOpenIcon,
    UserCircleIcon,
    MenuIcon,
    DocumentReportIcon,
    ClockIcon,
  },
  data() {
    return {
      contextUser: store.state.contextUser,
      isAdmin: store.state.isAdmin,
      scrolledNav: null,
      mobile: true,
      mobileNav: null,
      windowWidth: null,
      bugReportModal: false,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    bugReportClicked() {
      if (this.mobileNav) this.mobileNav = false;
      if (this.isAdmin) {
        this.$router.push("/bugs");
      } else {
        this.bugReportModal = true;
      }
    },
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    updateScroll() {
      const scrollPosition = window.scrollY;
      this.scrolledNav = scrollPosition > 50;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
    },
    closeMobileNav() {
      this.mobileNav = false;
    },
  },
})
export default class NavBar extends Vue {}
