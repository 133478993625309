import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7659ab20"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "wrapper-recipe-tags-blocks-view"
}
const _hoisted_2 = {
  key: 0,
  class: "container-tag-recipes"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecipeTagsFilter = _resolveComponent("RecipeTagsFilter")!
  const _component_RecipeBlocksTableView = _resolveComponent("RecipeBlocksTableView")!

  return (_ctx.recipes.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showFilter)
          ? (_openBlock(), _createBlock(_component_RecipeTagsFilter, {
              key: 0,
              onUpdatedFilterTags: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('updatedFilterTags', $event))),
              givenTags: _ctx.recipes.map((recipeByTag) => recipeByTag.tag)
            }, null, 8, ["givenTags"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recipes, (row, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            (row.recipes.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("h2", null, _toDisplayString(row.tag), 1),
                  _createVNode(_component_RecipeBlocksTableView, {
                    recipes: row.recipes
                  }, null, 8, ["recipes"])
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}