import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44faa962"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "profile-friends-friends"
}
const _hoisted_2 = { class: "profile-friends-recipe-header" }
const _hoisted_3 = { class: "recipes-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_ArrowsExpandIcon = _resolveComponent("ArrowsExpandIcon")!
  const _component_RecipeBlocksTableView = _resolveComponent("RecipeBlocksTableView")!
  const _component_RecipeBlocksByTagView = _resolveComponent("RecipeBlocksByTagView")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
    : (_ctx.recipes.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h2", null, _toDisplayString(this.status.charAt(0) + this.status.substring(1).toLowerCase()), 1),
            (_ctx.status === 'PUBLISHED')
              ? (_openBlock(), _createBlock(_component_ArrowsExpandIcon, {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expand = !_ctx.expand))
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_Transition, null, {
              default: _withCtx(() => [
                (!_ctx.expand)
                  ? (_openBlock(), _createBlock(_component_RecipeBlocksTableView, {
                      key: 0,
                      onOnRecipeDeleted: _ctx.recipeDeleted,
                      "show-restore": _ctx.status === 'ARCHIVED',
                      recipes: _ctx.recipes,
                      "horizontal-slide": _ctx.horizontalSlide,
                      "vertical-slide": _ctx.verticalSlide
                    }, null, 8, ["onOnRecipeDeleted", "show-restore", "recipes", "horizontal-slide", "vertical-slide"]))
                  : (_openBlock(), _createBlock(_component_RecipeBlocksByTagView, {
                      key: 1,
                      recipes: _ctx.filteredRecipesByTags,
                      "show-filter": true,
                      onUpdatedFilterTags: _ctx.filterRecipesOnTags
                    }, null, 8, ["recipes", "onUpdatedFilterTags"]))
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
}