
import BlockProfileFriendList from "@/components/profile/blocks/BlockProfileFriendList.vue";
import { getFriendsByUserId, getUserById } from "@/components/FirebaseQueries";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import store from "@/main";
import { Options, Vue } from "vue-class-component";
import { User } from "@/types/User";

@Options({
  components: {
    LoaderCircle,
    BlockProfileFriendList,
  },
  props: {
    friend: {
      type: Object,
      required: true,
    },
    horizontalSlide: {
      default: false,
      type: Boolean,
    },
    verticalSlide: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      friends: [],
      loading: true,
      contextUserId: store.state.contextUser._id,
    };
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    async loadUsers(alteredUser: User) {
      this.loading = true;
      if (alteredUser) {
        this.friends[this.friends.indexOf(alteredUser)] = await getUserById(
          alteredUser._id
        );
        this.loading = false;
      } else {
        getFriendsByUserId(this.friend._id).then((friends) => {
          this.friends = friends.filter(
            (friend) => friend._id !== this.contextUserId
          );
          this.loading = false;
        });
      }
    },
  },
})
export default class ProfileFriendsFriends extends Vue {}
