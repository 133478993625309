import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-408449b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: _ctx.transitionEffect }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: "carousel-item",
        onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('mouseenter'))),
        onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('mouseout')))
      }, [
        _createElementVNode("img", {
          src: _ctx.slide,
          alt: ""
        }, null, 8, _hoisted_1)
      ], 544), [
        [_vShow, _ctx.currentSlide === _ctx.index]
      ])
    ]),
    _: 1
  }, 8, ["name"]))
}