export enum BugStatus {
  OPEN = "OPEN",
  IN_PROGRESS = "IN_PROGRESS",
  RESOLVED = "RESOLVED",
}

export interface Bug {
  _id: string;
  title: string;
  description: string;
  reportDate: string;
  reporter: string;
  githubLink: string;
  status: BugStatus;
  devComment: string;
}
