
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["servings", "updateRecipeData"],
  props: {
    servings: {
      type: Number,
      default: 0,
    },
  },
  beforeMount() {
    this.nServings = this.servings;
  },
  methods: {
    updateParent() {
      Math.abs(this.nServings);
      this.$emit("updateRecipeData", {
        key: "servings",
        value: this.nServings,
      });
    },
  },
})
export default class RecipeServings extends Vue {
  nServings?: number;
}
