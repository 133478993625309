import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("tr", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.data[$props.active].headers, (title, index) => {
        return (_openBlock(), _createElementBlock("th", { key: index }, _toDisplayString(title), 1))
      }), 128))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.data[$props.active].rows, (row, index) => {
      return (_openBlock(), _createElementBlock("tr", { key: index }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (element, index) => {
          return (_openBlock(), _createElementBlock("td", { key: index }, _toDisplayString(element), 1))
        }), 128))
      ]))
    }), 128))
  ]))
}