import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "wrapper-details"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_RecipeDetailsContent = _resolveComponent("RecipeDetailsContent")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_RecipeDetailsContent, {
          recipe: _ctx.recipe,
          showImages: true,
          "show-method-controls": true
        }, null, 8, ["recipe"])
      ]))
}