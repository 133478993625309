
import BlockProfileFriends from "@/components/profile/blocks/BlockProfileFriends.vue";
import BlockProfileTags from "@/components/profile/blocks/BlockProfileTags.vue";
import store from "@/main";
import ProfileHeader from "@/components/profile/ProfileHeader.vue";
import { getTagsByUserId } from "@/components/FirebaseQueries";
import firebase from "firebase/app";
import ProfileFriendsRecipes from "@/components/profile/friends/ProfileFriendsRecipes.vue";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    LoaderCircle,
    ProfileFriendsRecipes,
    ProfileHeader,
    BlockProfileTags,
    BlockProfileFriends,
  },
  data() {
    return {
      contextUser: store.state.contextUser,
      viewTagsOpen: false,
      archivedIds: [],
      loadingTags: true,
      tags: [],
      loading: false,
    };
  },
  mounted() {
    getTagsByUserId(this.contextUser._id).then((tags) => {
      if (tags) {
        this.tags = tags;
      }
      this.loadingTags = false;
    });
  },
  methods: {
    openViewTags() {
      this.viewTagsOpen = !this.viewTagsOpen;
    },
    logout() {
      firebase.auth().signOut();
    },
  },
})
export default class ProfileView extends Vue {}
