
import { XIcon } from "@heroicons/vue/outline";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { XIcon },
  emits: ["onCloseClicked"],
  props: {
    isOpen: Boolean,
  },
  watch: {
    isOpen(nVal, oVal) {
      if (nVal === oVal) return;
      if (nVal === true) {
        document.body.classList.add("modal-active");
      } else {
        document.body.classList.remove("modal-active");
      }
    },
  },
  methods: {
    callCloseFunction() {
      this.$emit("onCloseClicked");
    },
  },
})
export default class ModalView extends Vue {}
