import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-028ac70c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "wrapper-recipe-modify"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_RecipeForm = _resolveComponent("RecipeForm")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
    : (!_ctx.loading && _ctx.oRecipe)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_PageHeader, {
            "header-text": 'Currently modifying: ' + _ctx.oRecipe.title
          }, null, 8, ["header-text"]),
          _createVNode(_component_RecipeForm, {
            recipe: _ctx.oRecipe,
            "user-tags": _ctx.userTagsArray,
            "selected-tags": _ctx.recipeSelectedTags,
            onStartFlow: _ctx.startFlow,
            onUpdateRecipeData: _ctx.updateRecipeData,
            onAddRecipeImage: _ctx.addRecipeImage,
            onRemoveRecipeImage: _ctx.removeRecipeImage
          }, null, 8, ["recipe", "user-tags", "selected-tags", "onStartFlow", "onUpdateRecipeData", "onAddRecipeImage", "onRemoveRecipeImage"])
        ]))
      : _createCommentVNode("", true)
}