
export default {
  name: "MeasurementTable",
  props: {
    active: String,
  },
  data() {
    return {
      data: {
        water: {
          headers: ["Cups", "Fluid OZ", "TBSP", "tsp", "Liter/ Milliliter"],
          rows: [
            ["1 gallon", "4 quarts", "256 tbsp", "768 tsp", "4.1L"],
            ["4 cups", "1 quart", "64 tbsp", "192 tsp", "0.95L"],
            ["2 cups", "1 pint", "32 tbsp", "96 tsp", "470 ml"],
            ["1 cup", "8 oz", "16 tbsp", "48 tsp", "237 ml"],
            ["3/4 cup", "6 oz", "12 tbsp", "36 tsp", "177 ml"],
            ["2/3 cup", "5 oz", "11 tbsp", "32 tsp", "158 ml"],
            ["1/2 cup", "4 oz", "8 tbsp", "24 tsp", "118 ml"],
            ["1/3 cup", "3 oz", "5 tbsp", "16 tsp", "79 ml"],
            ["1/4 cup", "2 oz", "4 tbsp", "12 tsp", "59 ml"],
            ["1/8 cup", "1 oz", "2 tbsp", "6 tsp", "30 ml"],
            ["1/16 cup", "0.5 oz", "1 tbsp", "3 tsp", "15 ml"],
          ],
        },
        flour: {
          headers: ["Cups", "Grams"],
          rows: [
            ["1 gallon", "2 kg"],
            ["4 cups", "500 grams"],
            ["2 cups", "250 grams"],
            ["1 cup", "125 grams"],
            ["3/4 cup", "94 grams"],
            ["2/3 cup", "83 grams"],
            ["1/2 cup", "63 grams"],
            ["1/3 cup", "42 grams"],
            ["1/4 cup", "31 grams"],
            ["1/8 cup", "16 grams"],
            ["1/16 cup", "8 grams"],
          ],
        },
        sugar: {
          headers: ["Cups", "Grams"],
          rows: [
            ["1 gallon", "3.2 kg"],
            ["4 cups", "800 grams"],
            ["2 cups", "400 grams"],
            ["1 cup", "200 grams"],
            ["3/4 cup", "150 grams"],
            ["2/3 cup", "133 grams"],
            ["1/2 cup", "100 grams"],
            ["1/3 cup", "67 grams"],
            ["1/4 cup", "50 grams"],
            ["1/8 cup", "25 grams"],
            ["1/16 cup", "13 grams"],
          ],
        },
        temperatures: {
          headers: ["Fahrenheit", "Celsius"],
          rows: [
            ["100°F", "37°C"],
            ["150°F", "65°C"],
            ["200°F", "93°C"],
            ["250°F", "121°C"],
            ["300°F", "150°C"],
            ["325°F", "160°C"],
            ["350°F", "180°C"],
            ["375°F", "190°C"],
            ["400°F", "200°C"],
            ["425°F", "220°C"],
            ["450°F", "230°C"],
            ["500°F", "260°C"],
            ["525°F", "274°C"],
            ["550°F", "288°C"],
          ],
        },
      },
    };
  },
};
