import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea8a079e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "wrapper-recipe-estimated-time"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.nServings !== undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          type: "number",
          min: "0",
          placeholder: "Portion size",
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateParent && _ctx.updateParent(...args))),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nServings) = $event)),
          required: ""
        }, null, 544), [
          [_vModelText, _ctx.nServings]
        ])
      ]))
    : _createCommentVNode("", true)
}