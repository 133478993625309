
import firebase from "firebase/app";
import store from "@/main";
import ProfileHeader from "@/components/profile/ProfileHeader.vue";
import ProfileFriendsRecipes from "@/components/profile/friends/ProfileFriendsRecipes.vue";
import ProfileFriendsFriends from "@/components/profile/friends/ProfileFriendsFriends.vue";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    LoaderCircle,
    ProfileFriendsFriends,
    ProfileFriendsRecipes,
    ProfileHeader,
  },
  data() {
    return {
      loading: true,
      friend: Object,
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id === undefined) this.$router.replace("/Profile");
    firebase
      .firestore()
      .collection(store.state.COLLECTION_USERS)
      .doc(id)
      .get()
      .then((value) => (this.friend = value.data()))
      .finally(() => (this.loading = false));
  },
})
export default class FriendsView extends Vue {}
