
import CreateTags from "@/components/tags/CreateTags.vue";

export default {
  name: "BlockProfileTags",
  components: { CreateTags },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
};
