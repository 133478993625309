
import CommentView from "@/components/recipe/view/comments/CommentView.vue";
import store from "@/main";
import { QuillEditor } from "@vueup/vue-quill";
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";
import { Recipe } from "@/types/Recipe";
import {
  addEvent,
  deleteCommentById,
  updateRecipeComments,
} from "@/components/FirebaseMutations";
import { EventType } from "@/types/Event";
import { Comment } from "@/types/Comment";
import { makeId } from "@/components/GlobalFunctions";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";

@Options({
  components: { LoaderCircle, CommentView, QuillEditor },
  props: {
    recipe: Object as PropType<Recipe>,
  },
  methods: {
    addComment() {
      this.loading = true;
      this.recipe.comments.splice(0, 0, {
        _id: makeId(store.state.COLLECTION_RECIPES),
        authorId: this.contextUser._id,
        message: this.nMessage,
        creationDate: new Date().toISOString(),
        likes: 0,
        dislikes: 0,
        replies: [],
      } as Comment);

      updateRecipeComments(this.recipe).then(() => {
        this.loading = false;
        addEvent(EventType.ADDED_COMMENT, {
          authorId: this.recipe.authorId,
          recipeId: this.recipe._id,
          recipeName: this.recipe.title,
          linkUrl: `/Recipe/${this.recipe._id}`,
          photoUrl:
            this.recipe.images.length > 0 ? this.recipe.images[0].url : "",
          comment: document.querySelectorAll(".ql-editor")[0].innerHTML,
        }).then(() => {
          document.querySelectorAll(".ql-editor")[0].innerHTML = "";
          this.state = "none";
        });
      });
    },
    updateNewComment() {
      this.nMessage = document.querySelectorAll(".ql-editor")[0].innerHTML;
    },
    updateExistingComment() {
      if (this.commentToUpdate) {
        this.loading = true;
        this.commentToUpdate.message =
          document.querySelectorAll(".ql-editor")[1].innerHTML;
        // Replace element
        this.recipe.comments[
          this.recipe.comments.findIndex(
            (it: Comment) => it._id === this.commentToUpdate._id
          )
        ] = this.commentToUpdate;

        updateRecipeComments(this.recipe).finally(() => {
          this.state = "none";
          this.loading = false;
        });
      }
    },
    onEditClicked(comment: Comment) {
      document.querySelectorAll(".ql-editor")[1].innerHTML = comment.message;
      this.commentToUpdate = comment;
      this.state = "edit";
      const containerElem = document.getElementById(
        "recipe-container-comments"
      );
      if (containerElem?.scrollTop) {
        containerElem.scrollTop = 0;
      }
    },
    onDeleteClicked(comment: Comment) {
      if (!confirm("Are you sure you want to delete your comment?")) return;

      deleteCommentById(this.recipe?._id, comment._id).then((recipe) => {
        if (this.recipe?.comments) {
          this.recipe.comments = recipe.comments;
        }
      });
    },
  },
})
export default class RecipeCommentService extends Vue {
  state: "none" | "add" | "edit" = "none";
  nMessage = "";
  contextUser = store.state.contextUser;
  commentToUpdate?: Comment;
  loading = false;
}
