
import TagsContainer from "@/components/recipe/components/TagsContainer.vue";
import firebase from "firebase/app";
import store from "@/main";
import Carousel from "@/components/common/imagecarousel/ImageCarousel.vue";
import PageHeader from "@/components/common/header/PageHeader.vue";
import RecipeCommentService from "@/components/recipe/view/comments/RecipeCommentService.vue";
import {
  getTagsByUserId,
  getUserById,
  isRecipeMyFavourite,
} from "@/components/FirebaseQueries";
import UserIconName from "@/components/common/usercard/UserIconName.vue";
import {
  changeRecipeStatusById,
  favouriteRecipe,
  unFavouriteRecipe,
} from "@/components/FirebaseMutations";
import { StarIcon as StarSolidIcon } from "@heroicons/vue/solid";
import {
  ArchiveIcon,
  EyeOffIcon,
  PencilAltIcon,
  StarIcon as StarOutlineIcon,
  UsersIcon,
} from "@heroicons/vue/outline";
import { Options, Vue } from "vue-class-component";
import { Recipe, RecipeStatus } from "@/types/Recipe";
import { PropType } from "vue";

@Options({
  props: {
    recipe: Object as PropType<Recipe>,
    showImages: Boolean,
    showMethodControls: Boolean,
  },
  data() {
    return {
      contextUser: store.state.contextUser,
      authorUser: {},
      step: 0,
      slides: [],
      tags: [],
      favourite: false,
    };
  },
  components: {
    UserIconName,
    RecipeCommentService,
    PageHeader,
    Carousel,
    TagsContainer,
    PencilAltIcon,
    ArchiveIcon,
    StarOutlineIcon,
    StarSolidIcon,
    EyeOffIcon,
    UsersIcon,
  },
  async mounted() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const $ = require("jquery");
    this.favourite = await isRecipeMyFavourite(this.recipe._id);

    // Set tags from the author
    getTagsByUserId(this.recipe.authorId).then((tags) => {
      if (tags) {
        this.tags = tags.filter((tag) => this.recipe.tags.includes(tag._id));
      }
    });
    getUserById(this.recipe.authorId).then((user) => (this.authorUser = user));
    $(".container-recipe-element").on("click", function (e: any) {
      if (e.target.tagName !== "LI") return;
      if (e.target.style.textDecoration === "line-through") {
        e.target.style.textDecoration = "none";
        e.target.style.color = "#ffffff";
      } else {
        e.target.style.textDecoration = "line-through";
        e.target.style.color = "#808080";
      }
    });
  },
  methods: {
    archiveRecipe() {
      if (!confirm(`Are you want to archive ${this.recipe.title}?`)) return;
      changeRecipeStatusById(this.recipe._id, RecipeStatus.ARCHIVED).then(() =>
        this.backToPathAllRecipes()
      );
    },
    backToPathAllRecipes() {
      this.$router.push("/Recipes");
    },
    removeIdFromUser(recipe: Recipe) {
      return new Promise(function (ok, no) {
        firebase
          .firestore()
          .collection(store.state.COLLECTION_USERS)
          .doc(recipe.authorId)
          .update({
            "recipeData.recipes": firebase.firestore.FieldValue.arrayRemove(
              recipe._id
            ),
          })
          .then(() => ok("Removed recipeId from user"))
          .catch((reason) => no(reason));
      });
    },
    favouriteRecipe() {
      favouriteRecipe(this.recipe._id, this.recipe.authorId).then(
        () => (this.favourite = true)
      );
    },
    unFavouriteRecipe() {
      if (
        !confirm("Are you sure you want to remove this recipe from favourites?")
      )
        return;
      unFavouriteRecipe(this.recipe._id, this.recipe.authorId).then(
        () => (this.favourite = false)
      );
    },
  },
})
export default class RecipeDetailsContent extends Vue {}
