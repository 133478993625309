
import PageHeader from "@/components/common/header/PageHeader.vue";
import BugsListView from "@/components/bugs/BugsListView.vue";
import store from "@/main";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { BugsListView, PageHeader },
  data() {
    return {
      isAdmin: store.state.isAdmin,
    };
  },
})
export default class BugsReport extends Vue {}
