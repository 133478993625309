import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wrapper-bugs"
}
const _hoisted_2 = { class: "container-bugs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_BugsListView = _resolveComponent("BugsListView")!

  return (_ctx.isAdmin)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PageHeader, { "header-text": "Bugs" }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BugsListView)
        ])
      ]))
    : _createCommentVNode("", true)
}