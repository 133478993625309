
import firebase from "firebase/app";
import { getErrorCodeMessage, InvalidUsernames } from "@/components/Constants";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { LoaderCircle },
  data() {
    return {
      email: "",
      password: "",
      passwordSecond: "",
      errorMessage: undefined,
      valChars: false,
      valUpper: false,
      valNum: false,
      valSpec: false,
      loading: false,
      specialChars: /[#?!@$%^&*-]/,
    };
  },
  methods: {
    signupuser(e: SubmitEvent) {
      e.preventDefault();
      this.loading = true;
      if (this.passwordValidation() && this.password === this.passwordSecond) {
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.email, this.password)
          .then(() => {
            this.loading = false;
          })
          .catch((reason) => {
            this.loading = false;
            this.errorMessage = getErrorCodeMessage(reason.code);
          });
      } else {
        this.loading = false;
        this.errorMessage = getErrorCodeMessage("auth/passwords-do-not-match");
      }
    },
    usernameValidation() {
      if (!this.username.match(this.specialChars)) {
        if (!InvalidUsernames.includes(this.username)) {
          this.errorMessage = undefined;
          this.valUsername = true;
        } else {
          this.valUsername = false;
          this.errorMessage = getErrorCodeMessage("auth/username-not-allowed");
        }
      } else {
        this.valUsername = false;
        this.errorMessage = getErrorCodeMessage(
          "auth/username-invalid-characters"
        );
      }
      this.checkStatusSubmitButton();
      return this.valUsername;
    },
    passwordValidation() {
      const upperCaseLetters = /[A-Z]/g;
      const numbers = /[0-9]/g;

      if (this.password.length > 8) {
        document.getElementById("password-val-chars")?.classList.add("cross");
        this.valChars = true;
      } else {
        document
          .getElementById("password-val-chars")
          ?.classList.remove("cross");
        this.valChars = false;
      }
      if (this.password.match(upperCaseLetters)) {
        document.getElementById("password-val-upper")?.classList.add("cross");
        this.valUpper = true;
      } else {
        document
          .getElementById("password-val-upper")
          ?.classList.remove("cross");
        this.valUpper = false;
      }
      if (this.password.match(numbers)) {
        document.getElementById("password-val-num")?.classList.add("cross");
        this.valNum = true;
      } else {
        document.getElementById("password-val-num")?.classList.remove("cross");
        this.valNum = false;
      }
      if (this.password.match(this.specialChars)) {
        document.getElementById("password-val-special")?.classList.add("cross");
        this.valSpec = true;
      } else {
        document
          .getElementById("password-val-special")
          ?.classList.remove("cross");
        this.valSpec = false;
      }

      this.checkStatusSubmitButton();

      return (
        this.password.length > 8 &&
        this.password.match(upperCaseLetters) &&
        this.password.match(numbers) &&
        this.password.match(this.specialChars)
      );
    },
    checkStatusSubmitButton() {
      const elemButton = document.getElementById(
        "button-submit"
      ) as HTMLButtonElement;
      if (!elemButton) return;
      if (this.valChars && this.valNum && this.valUpper && this.valSpec) {
        elemButton.classList.remove("disabled");
        elemButton.disabled = false;
      } else {
        elemButton.classList.add("disabled");
        elemButton.disabled = true;
      }
    },
  },
})
export default class SignupForm extends Vue {}
