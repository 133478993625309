import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d6f68d0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-profile-profile-picture" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "edit-profile-picture"
}
const _hoisted_4 = { style: {"display":"none"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(`container-profile-profile-picture ${
        _ctx.editable ? '' : 'not-editable'
      }`),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadProfilePicture && _ctx.uploadProfilePicture(...args)))
          }, [
            _createElementVNode("img", {
              id: "profile-picture",
              src: 
          _ctx.displayPicture === null
            ? require('@/assets/profile/placeholder_profile_picture.png')
            : _ctx.displayPicture
        ,
              alt: "Profile picture"
            }, null, 8, _hoisted_2),
            (_ctx.showEdit)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Edit"))
              : _createCommentVNode("", true)
          ], 2))
    ]),
    _createElementVNode("form", _hoisted_4, [
      _createElementVNode("input", {
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFileUpload && _ctx.onFileUpload(...args))),
        id: "image-upload",
        type: "file",
        accept: "image/*",
        "data-crop": ""
      }, null, 32)
    ])
  ], 64))
}