
import store from "@/main";
import PageHeader from "@/components/common/header/PageHeader.vue";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import RecipeBlocksByTagView from "@/components/recipe/view/RecipeBlocksByTagView.vue";
import { getRecipesByTag } from "@/components/FirebaseMutations";
import { Options, Vue } from "vue-class-component";
import { Tag } from "@/types/Tag";

@Options({
  components: {
    RecipeBlocksByTagView,
    LoaderCircle,
    PageHeader,
  },
  data() {
    return {
      allRecipesByTag: [],
      filteredRecipesByTags: [],
      loading: true,
      contextUser: store.state.contextUser,
    };
  },
  async mounted() {
    await this.loadAllRecipes();
  },
  methods: {
    async loadAllRecipes() {
      this.loading = true;
      this.allRecipesByTag = await getRecipesByTag(
        this.contextUser._id,
        true,
        false
      );
      this.filteredRecipesByTags = this.allRecipesByTag;
      this.loading = false;
    },
    filterRecipesOnTags(tags: Tag[]) {
      if (tags.length === 0) {
        this.filteredRecipesByTags = this.allRecipesByTag;
      } else {
        this.filteredRecipesByTags = [...this.allRecipesByTag].filter(
          (recipeByTag) => tags.includes(recipeByTag.tag)
        );
      }
    },
  },
})
export default class RecipesView extends Vue {}
