import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d23c3ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "wrapper-comment-view"
}
const _hoisted_2 = { class: "comment-view-header" }
const _hoisted_3 = { class: "header-left" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "author-controls"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_PencilIcon = _resolveComponent("PencilIcon")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
    : (_ctx.author)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: 
            _ctx.author.profilePicture === null
              ? require('@/assets/profile/placeholder_profile_picture.png')
              : _ctx.author.profilePicture
          ,
                alt: ""
              }, null, 8, _hoisted_4),
              _createElementVNode("h3", null, _toDisplayString(_ctx.author.displayName), 1)
            ]),
            (_ctx.isAuthor)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_PencilIcon, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onEditClicked', _ctx.comment)))
                  }),
                  _createVNode(_component_TrashIcon, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onDeleteClicked', _ctx.comment)))
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: "comment-message",
            innerHTML: _ctx.comment.message
          }, null, 8, _hoisted_6),
          _createElementVNode("div", {
            class: "comment-creadion-date",
            innerHTML: _ctx.formattedDate
          }, null, 8, _hoisted_7)
        ]))
      : _createCommentVNode("", true)
}