
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["updateRecipeData"],
  props: {
    isPrivate: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    this.nPrivate = this.isPrivate;
  },
  methods: {
    updateParent() {
      this.$emit("updateRecipeData", {
        key: "isPrivate",
        value: this.nPrivate,
      });
    },
  },
})
export default class RecipePrivate extends Vue {
  nPrivate!: boolean;
}
