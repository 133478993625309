import { addEvent } from "@/components/FirebaseMutations";
import { EventType } from "@/types/Event";
import { Recipe, RecipeImage } from "@/types/Recipe";

const addEventRecipeAdded = async (recipe: Recipe): Promise<void> => {
  await addEvent(EventType.ADDED_RECIPE, {
    recipeId: recipe._id,
    recipeName: recipe.title,
    linkUrl: `/Recipe/${recipe._id}`,
    photoUrl:
      recipe.images && recipe.images.length > 0
        ? (recipe.images[0] as RecipeImage).url
        : "",
  });
};

interface EventService {
  addEventRecipeAdded: (recipe: Recipe) => Promise<void>;
}

const eventService: EventService = { addEventRecipeAdded };

export default eventService;
