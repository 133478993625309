import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-509a6bea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carousel-indicators" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.total, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["carousel-indicator-item", { active: $props.currentIndex === index }]),
        key: index,
        onClick: ($event: any) => (_ctx.$emit('switch', index))
      }, null, 10, _hoisted_2))
    }), 128))
  ]))
}