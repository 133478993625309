
import { PropType } from "vue";
import { User } from "@/types/User";

export default {
  name: "EventUserComponent",
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
};
