
import { ChevronLeftIcon } from "@heroicons/vue/outline";
import Measurements from "@/components/common/sidepanel/measurements/MeasurementsView.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { Measurements, ChevronLeftIcon },
  data() {
    return {
      isOpen: false,
      contentWidth: "50px",
    };
  },
  methods: {
    toggleSidebar() {
      this.isOpen = !this.isOpen;
      this.contentWidth = this.isOpen ? "100%" : "50px";
    },
  },
})
export default class SidePanel extends Vue {}
