
import { convertStringToFormattedDate } from "@/components/GlobalFunctions";
import EventUserComponent from "@/components/timeline/event/EventUserComponent.vue";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { getUserById } from "@/components/FirebaseQueries";
import { getMessageFromEventType } from "@/components/Constants";
import { Options, Vue } from "vue-class-component";
import { Event, EventType } from "@/types/Event";
import { PropType } from "vue";
import { User } from "@/types/User";
import store from "@/main";

@Options({
  components: { LoaderCircle, EventUserComponent },
  props: {
    event: Object as PropType<Event>,
  },
  data() {
    return {
      loading: true,
      message: "",
      formattedDate: null,
      photoUrl: undefined,
    };
  },
  async mounted() {
    this.loading = true;
    this.formattedDate = convertStringToFormattedDate(this.event.date);
    if (
      this.event?.type === EventType.ADDED_FRIEND &&
      // Check if requester is not me, then show them. Else show me for them
      this.event.data?.friendRequestSentTo !== store.state.contextUser._id
    ) {
      this.user = await getUserById(this.event.data?.friendRequestSentTo);
    } else {
      this.user = await getUserById(this.event.userId);
    }
    this.message = getMessageFromEventType(this.event, this.user);
    this.loading = false;
    this.photoUrl = this.event?.data?.photoUrl || "";
  },
})
export default class EventListItem extends Vue {
  user?: User;
}
