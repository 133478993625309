import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6779a8de"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "container-drop-down",
    style: _normalizeStyle({ width: _ctx.width + 'px' })
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleContent && _ctx.toggleContent(...args))),
      class: "container-drop-down-header"
    }, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.headerText), 1),
      _createElementVNode("div", {
        class: "chevron-container",
        id: _ctx.headerText
      }, [
        _createVNode(_component_ChevronRightIcon)
      ], 8, _hoisted_1)
    ]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.stateIsShowContent)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "container-drop-down-content",
              style: _normalizeStyle({ maxHeight: _ctx.maxHeight + 'px' })
            }, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 4))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 4))
}