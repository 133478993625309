
import RecipeBlockCompact from "@/components/recipe/view/RecipeBlockCompact.vue";

export default {
  name: "RecipeBlocksTableView",
  components: { RecipeBlockCompact },
  props: {
    recipes: {
      type: Array,
      required: true,
    },
    showRestore: {
      type: Boolean,
      required: false,
    },
    horizontalSlide: {
      type: Boolean,
      required: false,
    },
    verticalSlide: {
      type: Boolean,
      required: false,
    },
  },
};
