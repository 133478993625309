import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "wrapper-friends-list" }
const _hoisted_2 = {
  key: 0,
  class: "container-users"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FriendsListRow = _resolveComponent("FriendsListRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.users.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
            return (_openBlock(), _createElementBlock("div", {
              key: user._id,
              class: "container-user-row"
            }, [
              _createVNode(_component_FriendsListRow, {
                onOnFriendMutated: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onFriendMutated'))),
                user: user,
                mode: _ctx.mode,
                onOnCheckboxSelected: _ctx.onCheckboxSelected
              }, null, 8, ["user", "mode", "onOnCheckboxSelected"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}