
import firebase from "firebase/app";
import { getErrorCodeMessage } from "@/components/Constants";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { LoaderCircle },
  data() {
    return {
      email: "",
      password: "",
      error_message: undefined,
      loading: false,
      mode: "signin",
    };
  },
  methods: {
    signinUser(e: SubmitEvent) {
      e.preventDefault();
      this.error_message = undefined;
      this.loading = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .catch(
          (reason) => (this.error_message = getErrorCodeMessage(reason.code))
        )
        .finally(() => (this.loading = false));
    },
    forgotPassword(e: SubmitEvent) {
      e.preventDefault();
      this.loading = true;
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => (this.mode = "reset-email-sent"))
        .catch(
          (reason) => (this.error_message = getErrorCodeMessage(reason.code))
        )
        .finally(() => (this.loading = false));
    },
  },
})
export default class LoginForm extends Vue {}
