import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ebf9a3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "wrapper-event"
}
const _hoisted_2 = { class: "event-left" }
const _hoisted_3 = { class: "event-date" }
const _hoisted_4 = { class: "event-message" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "event-right" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventUserComponent = _resolveComponent("EventUserComponent")!
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formattedDate), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_EventUserComponent, { user: _ctx.user }, {
              default: _withCtx(() => [
                _createElementVNode("div", { innerHTML: _ctx.message }, null, 8, _hoisted_5)
              ]),
              _: 1
            }, 8, ["user"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.photoUrl)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: `${_ctx.event.data.linkUrl}`
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: _ctx.photoUrl,
                    alt: ""
                  }, null, 8, _hoisted_7)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}