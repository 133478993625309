
import store from "@/main";
import FriendsListRow from "@/components/profile/friends/AddFriendsListRow.vue";
import { getUserById } from "@/components/FirebaseQueries";
import { Options, Vue } from "vue-class-component";
import { FriendFormRequest } from "@/types/FriendForm";

@Options({
  components: { FriendsListRow },
  props: {
    requests: Array,
    from: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      requestUsers: [],
      contextUserId: store.state.contextUser._id,
    };
  },
  mounted() {
    this.requestUsers = [];
    this.requests.forEach((request: FriendFormRequest) =>
      getUserById(this.from ? request.requester : request.userToBefriend).then(
        (user) =>
          this.requestUsers.push({
            request: request,
            user: user,
          })
      )
    );
  },
  methods: {
    onFriendMutated() {
      this.$emit("onFriendMutated");
    },
  },
})
export default class PendingFriends extends Vue {}
