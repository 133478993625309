import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-492a1998"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "per-recipe" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecipeBlockCompact = _resolveComponent("RecipeBlockCompact")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`container-recipes ${$props.horizontalSlide ? 'horizontal-slide' : ''} ${
      $props.verticalSlide ? 'vertical-slide' : ''
    }`)
  }, [
    (!$props.showRestore)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`container-recipes ${$props.horizontalSlide ? 'horizontal-slide' : ''} ${
        $props.verticalSlide ? 'vertical-slide' : ''
      }`)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.recipes, (recipe) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              key: recipe._id,
              to: '/Recipe/' + recipe._id
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_RecipeBlockCompact, {
                    "show-restore": $props.showRestore,
                    recipe: recipe
                  }, null, 8, ["show-restore", "recipe"])
                ])
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ], 2))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($props.recipes, (recipe) => {
          return (_openBlock(), _createElementBlock("div", {
            key: recipe._id,
            class: "per-recipe"
          }, [
            _createVNode(_component_RecipeBlockCompact, {
              onOnRecipeDeleted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onRecipeDeleted', this))),
              "show-restore": $props.showRestore,
              recipe: recipe
            }, null, 8, ["show-restore", "recipe"])
          ]))
        }), 128))
  ], 2))
}