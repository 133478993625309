import { Comment } from "@/types/Comment";

export interface RecipeData {
  key: string;
  value: string;
}

export interface RecipesByTag {
  tag: string;
  recipes: Recipe[];
}

export interface RecipeImage {
  index: number;
  url: string;
  name: string;
}

export enum RecipeStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  ARCHIVED = "ARCHIVED",
}

export interface Recipe {
  _id: string;
  creationDate: string;
  authorId: string;
  tags: string[];
  images?: RecipeImage[];
  isPrivate: boolean;
  title: string;
  comments: Comment[];
  status: RecipeStatus;
  updatedDate: string;
  publishDate: string;
  prepTime: number;
  cookTime: number;
  servings: number;
}
