
export default {
  name: "CarouselButtons",
  data() {
    return {
      left: "<",
      right: ">",
    };
  },
  emits: ["prev", "next"],
};
