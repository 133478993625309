
import { addEvent, createBugReport } from "@/components/FirebaseMutations";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { Options, Vue } from "vue-class-component";
import { EventType } from "@/types/Event";

@Options({
  components: { LoaderCircle },
  emits: ["onSubmit"],
  data() {
    return {
      title: "",
      body: "",
      loading: false,
    };
  },
  methods: {
    async submitForm(e: SubmitEvent) {
      e.preventDefault();
      this.loading = true;
      await createBugReport(this.title, this.body);
      await addEvent(EventType.ADDED_BUG, { bugTitle: this.title });
      this.$emit("onSubmit");
    },
  },
})
export default class BugReportModalContent extends Vue {}
