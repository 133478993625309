
export default {
  name: "TagsContainer",
  props: {
    tags: {
      type: Array,
      required: true,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
};
