import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66390b32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-header" }
const _hoisted_2 = { class: "container-slot" }
const _hoisted_3 = { class: "container-slot-footer" }
const _hoisted_4 = { class: "container-navigation-dots" }
const _hoisted_5 = { class: "container-navigation-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationDots = _resolveComponent("NavigationDots")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.subText), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock("div", {
        class: "container-slot-content",
        key: _ctx.index
      }, [
        _createVNode(_Transition, {
          mode: "out-in",
          appear: ""
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        })
      ])),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_NavigationDots, {
            count: 3,
            index: _ctx.index
          }, null, 8, ["index"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.index > 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass(`walkthrough-navigation-buttons previous`),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateIndex(_ctx.index - 1)))
              }, " Previous "))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: _normalizeClass(`walkthrough-navigation-buttons next`),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateIndex(_ctx.index + 1)))
          }, _toDisplayString(_ctx.buttonNextLabel), 1)
        ])
      ])
    ])
  ], 64))
}