
import PageHeader from "@/components/common/header/PageHeader.vue";
import { getRecipeById, getTagsByUserId } from "@/components/FirebaseQueries";
import {
  removeAllImages,
  updateRecipe,
  uploadFiles,
} from "@/components/FirebaseMutations";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import RecipeForm from "@/components/recipe/components/RecipeForm.vue";
import { DefaultReturnPath } from "@/components/Constants";
import Sidepanel from "@/components/common/sidepanel/SidePanel.vue";
import { Options, Vue } from "vue-class-component";
import { Recipe, RecipeData, RecipeImage, RecipeStatus } from "@/types/Recipe";
import { correctIndexOfRecipeImages } from "@/components/GlobalFunctions";
import store from "@/main";
import eventService from "@/services/EventService";

@Options({
  emits: ["startFlow", "addRecipeImage"],
  data() {
    return {
      loading: true,
      userTagsArray: [],
      recipeSelectedTags: [],
      contextUserId: store.state.contextUser._id,
    };
  },
  components: {
    Sidepanel,
    RecipeForm,
    LoaderCircle,
    PageHeader,
  },
  async mounted() {
    const recipeId = this.$route.params.id.toString();
    if (recipeId === undefined) this.$router.push(DefaultReturnPath);
    // Get Recipe
    const recipe = await getRecipeById(recipeId);

    if (recipe.authorId !== this.contextUserId) {
      alert("You do not have permission to modify this recipe");
      this.$router.back();
    }

    this.oRecipe = recipe;
    this.nRecipe = recipe;
    // Get Tags
    const tags = await getTagsByUserId(this.oRecipe.authorId);
    if (tags) {
      // Set all tags
      this.userTagsArray = [...tags];
      // Set selected tags
      this.recipeSelectedTags = [...tags].filter((tag) =>
        this.oRecipe.tags.includes(tag._id)
      );
    }
    this.loading = false;
  },
  methods: {
    updateRecipeIngredients() {
      this.updateRecipeData({
        key: "ingredients",
        value: document.querySelectorAll(".ql-editor")[0].innerHTML,
      });
    },
    updateRecipeMethod() {
      this.updateRecipeData({
        key: "method",
        value: document.querySelectorAll(".ql-editor")[1].innerHTML,
      });
    },
    updateRecipeData(data: RecipeData) {
      this.nRecipe[data.key] = data.value;
      if (data.key === "status") {
        console.log("New Status: " + this.nRecipe.status);
      }
    },
    addRecipeImage(blobs: { file: Blob[]; index: number }[]) {
      this.imagesToAddByBlob = blobs
        .sort((a, b) => a.index - b.index)
        .map((blob) => blob.file);
    },
    removeRecipeImage(imageToRemove: RecipeImage) {
      this.imagesToRemoveByName.push(imageToRemove.name);
    },
    async startFlow() {
      this.loading = true;

      this.nRecipe.updatedDate = new Date().toISOString();

      if (
        this.nRecipe.publishDate.length === 0 &&
        this.nRecipe.status === RecipeStatus.PUBLISHED &&
        !this.nRecipe.isPrivate
      ) {
        this.nRecipe.publishDate = this.nRecipe.updatedDate;
        this.sendEvent = true;
      }

      await this.replaceImages();
    },
    async replaceImages() {
      if (this.imagesToRemoveByName.length > 0) {
        await removeAllImages(this.nRecipe._id, this.imagesToRemoveByName);
        this.nRecipe.images = correctIndexOfRecipeImages(this.nRecipe.images);
      }
      if (this.imagesToAddByBlob.length > 0) {
        const uploadedFiles = await uploadFiles(
          this.nRecipe._id,
          this.imagesToAddByBlob,
          this.nRecipe.images?.length
        );
        this.nRecipe.images = [...this.nRecipe.images, ...uploadedFiles];
      }
      // Upload document
      this.uploadDocument();
    },
    uploadDocument() {
      updateRecipe(this.nRecipe).then(() => this.addToEvents());
    },
    addToEvents() {
      if (this.sendEvent) {
        eventService
          .addEventRecipeAdded(this.nRecipe)
          .then(() => this.$router.push(`/Recipe/${this.nRecipe._id}`));
      } else {
        this.$router.push(`/Recipe/${this.nRecipe._id}`);
      }
    },
  },
})
export default class ModifyRecipe extends Vue {
  oRecipe?: Recipe = undefined;
  nRecipe?: Recipe = undefined;
  imagesToRemoveByName: string[] = [];
  imagesToAddByBlob: Blob[] = [];
  sendEvent = false;
}
