import firebase from "firebase/app";
import store from "@/main";
import { Tag } from "@/types/Tag";
import {
  FriendFormAccepted,
  FriendFormAcceptedPending,
  FriendFormRequest,
  FriendFormSentReceived,
} from "@/types/FriendForm";
import { FriendStatus, User, UserRole } from "@/types/User";
import { Recipe, RecipeStatus } from "@/types/Recipe";
import { Bug } from "@/types/Bug";
import { Event } from "@/types/Event";
import { sortBugs } from "@/utils/SortUtils";
import { collectionRecipes, collectionUsers } from "@/components/Constants";

type GetTagsByUserId = (userId: string) => Promise<Tag[]>;
export const getTagsByUserId: GetTagsByUserId = async (userId) => {
  return await firebase
    .firestore()
    .collection(store.state.COLLECTION_TAGS)
    .doc(userId)
    .get()
    .then((doc) => {
      return doc.get("tags") as Tag[];
    });
};

type GetAllAcceptedPendingFriendsListByUserId = () => Promise<
  FriendFormAcceptedPending[]
>;
export const getAllAcceptedPendingFriendsLists: GetAllAcceptedPendingFriendsListByUserId =
  async () => {
    return await firebase
      .firestore()
      .collection(store.state.COLLECTION_FRIENDS)
      .get()
      .then((snapshot) => {
        return snapshot.docs.map(
          (doc) => doc.data() as FriendFormAcceptedPending
        );
      });
  };

type GetAcceptedPendingFriendsListByUserId = (
  userId: string
) => Promise<FriendFormAcceptedPending>;
export const getAcceptedPendingFriendsListByUserId: GetAcceptedPendingFriendsListByUserId =
  async (userId) => {
    return await firebase
      .firestore()
      .collection(store.state.COLLECTION_FRIENDS)
      .doc(userId)
      .get()
      .then((snapshot) => {
        const data: FriendFormAcceptedPending = { accepted: [], pending: [] };
        if (snapshot.get("accepted")) data.accepted = snapshot.get("accepted");
        if (snapshot.get("pending")) data.pending = snapshot.get("pending");
        return data;
      });
  };

type GetRecipeById = (recipeId: string) => Promise<Recipe>;
export const getRecipeById: GetRecipeById = (recipeId) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_RECIPES)
    .doc(recipeId)
    .get()
    .then((snapshot) => snapshot.data() as Recipe);
};

type GetRecipesById = (recipesIds: string[]) => Promise<Recipe[]>;
export const getRecipesById: GetRecipesById = async (recipeIds) => {
  const recipeSnapshots = await firebase
    .firestore()
    .collection(store.state.COLLECTION_RECIPES)
    .where("_id", "in", recipeIds)
    .get();
  return recipeSnapshots.docs.map((doc) => doc.data() as Recipe) as Recipe[];
};

type GetUserById = (userId: string) => Promise<User>;
export const getUserById: GetUserById = (userId) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_USERS)
    .doc(userId)
    .get()
    .then((snapshot) => snapshot.data() as User);
};

type GetAllUsersExcept = (userIds: string[]) => Promise<User[]>;
export const getAllUsersExcept: GetAllUsersExcept = (userIds) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_USERS)
    .where("role", "in", [UserRole.USER, UserRole.ADMIN])
    .get()
    .then((snapshots) => {
      return snapshots.docs
        .map((snapshot) => snapshot.data() as User)
        .filter(
          (user) =>
            user.displayName &&
            user.displayName.length > 0 &&
            user.walkthroughComplete &&
            !userIds.includes(user._id)
        );
    });
};

type GetMePendingSentReceivedFriendRequest =
  () => Promise<FriendFormSentReceived>;
export const getMePendingSentReceivedFriendRequest: GetMePendingSentReceivedFriendRequest =
  () => {
    return firebase
      .firestore()
      .collection(store.state.COLLECTION_FRIENDS)
      .doc(store.state.contextUser._id)
      .get()
      .then((snapshot) => {
        const data: FriendFormSentReceived = { sent: [], received: [] };
        const pendingRequest = snapshot.get("pending") as FriendFormRequest[];
        if (pendingRequest) {
          pendingRequest.forEach((request) => {
            if (request.requester === store.state.contextUser._id) {
              data.sent.push(request);
            } else {
              data.received.push(request);
            }
          });
        }
        return data;
      });
  };

type GetFriendsByUserId = (userId: string) => Promise<User[]>;
export const getFriendsByUserId: GetFriendsByUserId = async (userId) => {
  const friendsList: FriendFormAcceptedPending =
    await getAcceptedPendingFriendsListByUserId(userId);
  if (friendsList?.accepted && friendsList?.accepted.length > 0) {
    const snapshots = await firebase
      .firestore()
      .collection(store.state.COLLECTION_USERS)
      .get();
    return snapshots.docs
      .map((doc) => doc.data() as User)
      .filter((user) =>
        friendsList.accepted
          .map((response) => response.friendId)
          .includes(user._id)
      ) as User[];
  }
  return [];
};

type GetRecipesByUserId = (
  userId: string,
  status: RecipeStatus,
  hidePrivate: boolean
) => Promise<Recipe[]>;
export const getRecipesByUserId: GetRecipesByUserId = (
  userId,
  status,
  hidePrivate
) => {
  let query = firebase
    .firestore()
    .collection(store.state.COLLECTION_RECIPES)
    .where("authorId", "==", userId)
    .where("status", "==", status);
  if (hidePrivate) {
    query = query.where("isPrivate", "==", false);
  }
  return query.get().then((docs) =>
    [...docs.docs.map((doc) => doc.data() as Recipe)].sort(function (a, b) {
      return (
        new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
      );
    })
  );
};

type GetAllBugs = () => Promise<Bug[]>;
export const getAllBugs: GetAllBugs = () => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_BUGS)
    .get()
    .then((snapshots) => {
      return sortBugs([...snapshots.docs].map((doc) => doc.data() as Bug));
    });
};

type GetAllMyEvents = () => Promise<Event[]>;
export const getAllMyEvents: GetAllMyEvents = () => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_EVENTS)
    .where("includedFriends", "array-contains", store.state.contextUser._id)
    .get()
    .then((snapshots) =>
      snapshots.docs
        .map((doc) => doc.data() as Event)
        .sort(function (a, b) {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        })
    );
};

type IsMeBefriendedWith = (userId: string) => Promise<boolean>;
export const isMeBefriendedWith: IsMeBefriendedWith = (userId) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_FRIENDS)
    .doc(store.state.contextUser._id)
    .get()
    .then((snapshot) => {
      const accepted = snapshot.get("accepted");
      if (accepted) {
        return accepted.find(
          (acceptedFriend: FriendFormAccepted) =>
            acceptedFriend.friendId === userId
        );
      }
      return false;
    });
};

type IsRecipeMyFavourite = (recipeId: string) => Promise<boolean>;
export const isRecipeMyFavourite: IsRecipeMyFavourite = (recipeId) => {
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_USERS)
    .doc(store.state.contextUser._id)
    .get()
    .then((snapshot) => {
      const me = snapshot.data() as User;
      if (me.favouriteRecipes && me.favouriteRecipes.length > 0) {
        return (
          me.favouriteRecipes.find(
            (favourite) => favourite.recipeId === recipeId
          ) !== undefined
        );
      } else {
        return false;
      }
    });
};

type GetAllMyFavouriteRecipes = () => Promise<Recipe[]>;
export const getAllMyFavouriteRecipes: GetAllMyFavouriteRecipes = async () => {
  const me = await getUserById(store.state.contextUser._id);
  try {
    if (me.favouriteRecipes !== undefined && me.favouriteRecipes.length > 0) {
      return (
        await getRecipesById(
          me.favouriteRecipes.map((favourite) => favourite.recipeId)
        )
      ).filter((recipe) => !recipe.isPrivate);
    }
  } catch (_) {
    // Do nothing
  }
  return [];
};

type GetMeFriendRequest = (userToAccept: string) => Promise<FriendFormRequest>;
export const getMeFriendRequest: GetMeFriendRequest = (userToAccept) => {
  const uid = store.state.contextUser._id;
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_FRIENDS)
    .doc(userToAccept)
    .get()
    .then((doc) =>
      doc.get("pending").find((elem: FriendFormRequest) => {
        return elem.requester === uid
          ? elem.userToBefriend === userToAccept
          : elem.userToBefriend === uid;
      })
    )
    .catch(() => false);
};

type GetAllUsersByRole = (role: UserRole) => Promise<User[]>;
export const getAllUsersByRole: GetAllUsersByRole = (role) => {
  return collectionUsers()
    .where("role", "==", role)
    .get()
    .then((snapshots) => snapshots.docs.map((doc) => doc.data() as User));
};

type GetMeFriendStatus = (userId: string) => Promise<FriendStatus>;
export const getMeFriendStatus: GetMeFriendStatus = (userId) => {
  const meid = store.state.contextUser._id;
  return firebase
    .firestore()
    .collection(store.state.COLLECTION_FRIENDS)
    .doc(meid)
    .get()
    .then((doc) => {
      const me = doc.data() as FriendFormAcceptedPending;
      // No friends or requests
      if (!me) {
        return FriendStatus.UNKNOWN;
      }
      // Get Accepted
      const statusAccepted =
        me?.accepted &&
        me.accepted.find((user) => user.friendId === userId)?.status;
      // Get pending or awaiting_response
      const statusElse =
        me.pending &&
        me.pending.find((user) => user.userToBefriend === userId)?.status;

      return statusAccepted || statusElse || FriendStatus.UNKNOWN;
    });
};

type GetAllRecipes = () => Promise<Recipe[]>;
export const getAllRecipes: GetAllRecipes = () => {
  return collectionRecipes()
    .get()
    .then((snapshots) => {
      return snapshots.docs.map((doc) => doc.data() as Recipe);
    });
};
