
import PageHeader from "@/components/common/header/PageHeader.vue";
import EventsList from "@/components/timeline/event/EventsList.vue";
import store from "@/main";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";

export default {
  name: "TimeLine",
  components: { LoaderCircle, EventsList, PageHeader },
  data() {
    return {
      contextUser: store.state.contextUser,
      eventIds: [],
      loading: false,
    };
  },
};
