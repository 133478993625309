
import store from "@/main";
import WalkthroughSlide1 from "@/components/walkthrough/slides/WalkthroughSlide1.vue";
import WalkthroughSlideTemplate from "@/components/walkthrough/WalkthroughSlideTemplate.vue";
import Slide2 from "@/components/walkthrough/slides/WalkthroughSlide2.vue";
import Slide3 from "@/components/walkthrough/slides/WalkthroughSlide3.vue";
import { completeMyWalkthrough } from "@/components/FirebaseMutations";
import { markRaw } from "vue";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import firebase from "firebase/app";
import { Options, Vue } from "vue-class-component";

@Options({
  components: { LoaderCircle, WalkthroughSlideTemplate, WalkthroughSlide1 },
  data() {
    return {
      loading: true,
      sliderIndex: 0,
      contextUser: store.state.contextUser,
      data: [
        {
          title: "Welcome to your Recipebook!",
          subText: "Check the fields below and correct them if needed.",
          template: markRaw(WalkthroughSlide1),
        },
        {
          title: "It's time to add some Tags!",
          subText:
            "Try to stay as generic as possible. Something like: Meat, Vegetarian, Salad, Dessert.",
          template: markRaw(Slide2),
        },
        {
          title: "Check what your friends are cooking!",
          subText: "Add friends and share some recipes.",
          template: markRaw(Slide3),
        },
      ],
    };
  },
  methods: {
    updateIndex(index: number) {
      if (index === this.data.length) {
        completeMyWalkthrough();
      } else {
        this.sliderIndex = index;
      }
    },
    signout() {
      firebase.auth().signOut();
    },
  },
  mounted() {
    if (this.contextUser.walkthroughComplete) this.$router.replace("/Home");
    this.loading = false;
  },
})
export default class WalkthroughView extends Vue {}
