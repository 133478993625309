import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-905e2fae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-friends-list-row" }
const _hoisted_2 = { class: "container-user-row-left" }
const _hoisted_3 = {
  key: 0,
  class: "container-checkbox"
}
const _hoisted_4 = {
  key: 1,
  class: "container-user-row-right"
}
const _hoisted_5 = {
  key: 2,
  class: "container-user-row-right"
}
const _hoisted_6 = {
  key: 3,
  class: "container-user-row-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserIconName = _resolveComponent("UserIconName")!
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_UserAddIcon = _resolveComponent("UserAddIcon")!
  const _component_UserRemoveIcon = _resolveComponent("UserRemoveIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.mode === 5)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSelected) = $event)),
              onChange: _cache[1] || (_cache[1] = ($event: any) => (
            _ctx.$emit('onCheckboxSelected', {
              _id: _ctx.user._id,
              isSelected: _ctx.isSelected,
            })
          ))
            }, null, 544), [
              [_vModelCheckbox, _ctx.isSelected]
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_UserIconName, {
        "profile-picture": _ctx.user.profilePicture,
        name: _ctx.user.displayName,
        "hide-background-color": true
      }, null, 8, ["profile-picture", "name"])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
      : (_ctx.mode === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.requestSent)
              ? (_openBlock(), _createBlock(_component_CheckIcon, { key: 0 }))
              : (_openBlock(), _createBlock(_component_UserAddIcon, {
                  key: 1,
                  onClick: _ctx.sendFriendRequest
                }, null, 8, ["onClick"]))
          ]))
        : (_ctx.mode === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", {
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.acceptFriendRequest && _ctx.acceptFriendRequest(...args)))
              }, [
                _createVNode(_component_UserAddIcon)
              ]),
              _createElementVNode("div", {
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.rejectFriendRequest && _ctx.rejectFriendRequest(...args)))
              }, [
                _createVNode(_component_UserRemoveIcon)
              ])
            ]))
          : (_ctx.mode === 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_UserRemoveIcon, { onClick: _ctx.removeFriendRequest }, null, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
  ]))
}