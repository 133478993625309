
import MeasurementTable from "@/components/common/sidepanel/measurements/MeasurementTable.vue";

export default {
  name: "MeasurementsView",
  components: { MeasurementTable },
  data() {
    return {
      active: "water",
    };
  },
};
