
import RecipeBlocksTableView from "@/components/recipe/view/RecipeBlocksTableView.vue";
import { getRecipesByUserId } from "@/components/FirebaseQueries";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { ArrowsExpandIcon } from "@heroicons/vue/outline";
import RecipeBlocksByTagView from "@/components/recipe/view/RecipeBlocksByTagView.vue";
import { getRecipesByTag } from "@/components/FirebaseMutations";
import { Options, Vue } from "vue-class-component";
import { Recipe, RecipesByTag, RecipeStatus } from "@/types/Recipe";
import { PropType } from "vue";
import { Tag } from "@/types/Tag";

@Options({
  components: {
    RecipeBlocksByTagView,
    LoaderCircle,
    RecipeBlocksTableView,
    ArrowsExpandIcon,
  },
  props: {
    friend: Object,
    horizontalSlide: {
      default: true,
      required: false,
    },
    verticalSlide: {
      default: false,
      required: false,
    },
    status: {
      type: Object as PropType<RecipeStatus>,
      default: RecipeStatus.PUBLISHED,
    },
  },
  data() {
    return {
      loading: true,
      expand: false,
    };
  },
  async mounted() {
    this.loading = true;
    this.recipes = await getRecipesByUserId(this.friend._id, this.status, true);
    this.recipesByTag = await getRecipesByTag(this.friend._id, false, true);
    this.filteredRecipesByTags = this.recipesByTag;
    this.loading = false;
  },
  methods: {
    recipeDeleted(recipeId: string) {
      this.loading = true;
      this.recipes.splice(
        this.recipes.findIndex((recipe: Recipe) => recipe._id === recipeId),
        1
      );
      this.loading = false;
    },
    filterRecipesOnTags(tags: Tag[]) {
      if (tags.length === 0) {
        this.filteredRecipesByTags = this.recipesByTag;
      } else {
        this.filteredRecipesByTags = [...this.recipesByTag].filter(
          (recipeByTag) => tags.includes(recipeByTag.tag)
        );
      }
    },
  },
})
export default class ProfileFriendsRecipes extends Vue {
  recipes: Recipe[] = [];
  recipesByTag: RecipesByTag[] = [];
  filteredRecipesByTags: RecipesByTag[] = [];
}
