import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c2efc7b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-recipe-images" }
const _hoisted_2 = { class: "recipe-images-content" }
const _hoisted_3 = { class: "container-images" }
const _hoisted_4 = { class: "per-image" }
const _hoisted_5 = { class: "per-image-delete" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      id: "hiddenInputPictures",
      style: {"display":"none"},
      type: "file",
      multiple: "",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleUploadImages && _ctx.handleUploadImages(...args))),
      accept: ".jpg, .jpeg, .png, .webp",
      alt: ""
    }, null, 32),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
          : (_openBlock(), _createBlock(_component_draggable, {
              key: 1,
              class: "container-draggable",
              modelValue: _ctx.images,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.images) = $event)),
              group: "people",
              onStart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.drag = true)),
              onEnd: _cache[3] || (_cache[3] = ($event: any) => (_ctx.drag = false)),
              "item-key": "index",
              onChange: _ctx.computeIndices
            }, {
              item: _withCtx(({ element }) => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_TrashIcon, {
                      onClick: ($event: any) => (_ctx.deleteImage(element))
                    }, null, 8, ["onClick"])
                  ]),
                  _createElementVNode("img", {
                    class: "image-preview",
                    src: element.url,
                    alt: ""
                  }, null, 8, _hoisted_6)
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "onChange"])),
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "image-placeholder",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.openInput && _ctx.openInput(...args)))
            }, [
              _createVNode(_component_PlusIcon)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}