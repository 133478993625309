import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45070dde"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["height", "width", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(
      $props.hideBackgroundColor
        ? 'wrapper-user-icon-name'
        : 'wrapper-user-icon-name background-color'
    ),
    style: _normalizeStyle({ padding: $props.padding + 'px' })
  }, [
    _createElementVNode("img", {
      height: $props.imageDimensions,
      width: $props.imageDimensions,
      style: _normalizeStyle({ 'margin-right': $props.imageMarginRight + 'px' }),
      src: 
        $props.profilePicture === null
          ? require('@/assets/profile/placeholder_profile_picture.png')
          : $props.profilePicture
      ,
      alt: ""
    }, null, 12, _hoisted_1),
    _createElementVNode("div", {
      class: "display-name",
      style: _normalizeStyle({ 'font-size': $props.fontSize + 'rem' })
    }, _toDisplayString($props.name), 5)
  ], 6))
}