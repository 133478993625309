
import RecipeDetailsContent from "@/components/recipe/view/RecipeDetailsContent.vue";
import store from "@/main";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import Sidepanel from "@/components/common/sidepanel/SidePanel.vue";
import {
  getRecipeById,
  isMeBefriendedWith,
} from "@/components/FirebaseQueries";
import { Options, Vue } from "vue-class-component";
import { Recipe, RecipeStatus } from "@/types/Recipe";

@Options({
  components: { Sidepanel, LoaderCircle, RecipeDetailsContent },
  data() {
    return {
      loading: true,
      contextUserId: store.state.contextUser._id,
    };
  },
  methods: {
    goBackToView(path: string) {
      this.$router.replace(path);
    },
  },
  async mounted() {
    if (this.$route.params.id === undefined) this.$router.back();
    const recipeId = this.$route.params.id.toString();
    this.recipe = await getRecipeById(recipeId);
    if (this.recipe === undefined) {
      alert("This recipe is deleted or doesn't exist");
      this.goBackToView("/Recipes");
    } else {
      const authorIsMyFriend = await isMeBefriendedWith(this.recipe.authorId);
      if (
        this.recipe.authorId !== this.contextUserId &&
        (this.recipe.isPrivate ||
          !authorIsMyFriend ||
          this.recipe.status !== RecipeStatus.PUBLISHED)
      ) {
        alert("You don't have permission to view this recipe");
        this.goBackToView("/Recipes");
      } else {
        this.loading = false;
      }
    }
  },
})
export default class RecipeDetailsView extends Vue {
  recipe?: Recipe;
}
