<template>
  <div class="wrapper-recipe-status">
    <label>
      {{ label }}
      <input type="checkbox" v-model="selected" @change="updateParent" />
    </label>
  </div>
</template>

<script>
import { RecipeStatus as Status } from "@/types/Recipe";
import { Options, Vue } from "vue-class-component";

@Options({
  name: "RecipeStatus",
  props: {
    label: String,
    status: {
      default: Status.PUBLISHED,
    },
  },
  mounted() {
    console.log("Initial: " + this.status);
    this.selectedValue = this.label.toUpperCase();
    this.selected = this.status === this.label.toUpperCase();
  },
  methods: {
    updateParent() {
      let value;
      if (this.selectedValue === Status.PUBLISHED.toString()) {
        if (this.selected) {
          value = Status.PUBLISHED;
        } else {
          value = Status.DRAFT;
        }
      } else {
        if (this.selected) {
          value = Status.DRAFT;
        } else {
          value = Status.PUBLISHED;
        }
      }
      this.$emit("updateRecipeData", {
        key: "status",
        value,
      });
    },
  },
})
export default class RecipeStatus extends Vue {
  selected = false;
  selectedValue = "";
}
</script>

<style lang="scss" scoped>
.wrapper-recipe-status label {
  display: flex;
  justify-content: space-between;

  &:first-child {
    width: 200px;
  }
}
</style>
