
export default {
  name: "NavigationDots",
  props: {
    count: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
};
