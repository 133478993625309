import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "wrapper-profile"
}
const _hoisted_2 = { class: "container-events" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_EventsList = _resolveComponent("EventsList")!

  return ($data.loading)
    ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PageHeader, { "header-text": "Timeline" }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_EventsList)
        ])
      ]))
}