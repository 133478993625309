
import AddFriendsModalContent from "@/components/profile/friends/AddFriendsModalContent.vue";
import PendingFriends from "@/components/profile/friends/PendingFriends.vue";
import store from "@/main";
import BlockProfileFriendList from "@/components/profile/blocks/BlockProfileFriendList.vue";
import Modal from "@/components/modal/ModalView.vue";
import {
  getFriendsByUserId,
  getMePendingSentReceivedFriendRequest,
} from "@/components/FirebaseQueries";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { PlusIcon } from "@heroicons/vue/solid";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    LoaderCircle,
    Modal,
    BlockProfileFriendList,
    AddFriendsModalContent,
    PendingFriends,
    PlusIcon,
  },
  data() {
    return {
      pendingSentRequests: [],
      pendingRequestRequests: [],
      friends: [],
      loading: true,
      modalOpen: false,
    };
  },
  mounted() {
    this.start();
  },
  methods: {
    start() {
      this.loading = true;
      getMePendingSentReceivedFriendRequest().then((requests) => {
        this.pendingRequestRequests = requests.received;
        this.pendingSentRequests = requests.sent;
        getFriendsByUserId(store.state.contextUser._id)
          .then((friendsList) => (this.friends = friendsList))
          .finally(() => (this.loading = false));
      });
    },
  },
})
export default class BlockProfileFriends extends Vue {}
