
import { ChevronRightIcon } from "@heroicons/vue/outline";
import { convertStringToFormattedDate } from "@/components/GlobalFunctions";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { updateBug } from "@/components/FirebaseMutations";
import UserIconName from "@/components/common/usercard/UserIconName.vue";
import { getUserById } from "@/components/FirebaseQueries";
import Modal from "@/components/modal/ModalView.vue";
import { Options, Vue } from "vue-class-component";
import { Bug, BugStatus } from "@/types/Bug";
import { PropType } from "vue";
import { User } from "@/types/User";

@Options({
  components: { Modal, UserIconName, LoaderCircle, ChevronRightIcon },
  emits: ["updateBugs"],
  props: {
    bug: {
      type: Object as PropType<Bug>,
      required: true,
    },
  },
  data() {
    return {
      expand: false,
      creationDate: "",
      loading: true,
      modalGitHub: false,
      modalDevComment: false,
    };
  },
  async mounted() {
    this.updatedBug = this.bug;
    this.creationDate = convertStringToFormattedDate(
      this.updatedBug.reportDate
    );
    this.author = await getUserById(this.updatedBug.reporter);
    this.loading = false;
  },
  methods: {
    githubClicked() {
      if (this.updatedBug.githubLink.length > 0) {
        window.open(this.updatedBug.githubLink);
      } else {
        this.modalGitHub = true;
      }
    },
    async updateBug() {
      this.loading = true;
      await updateBug(this.updatedBug);
      this.loading = false;
      this.modalGitHub = false;
      this.modalDevComment = false;
      this.$emit("updateBugs");
    },
  },
})
export default class BugsListItem extends Vue {
  updatedBug!: Bug;
  author!: User;
  statusOpen: BugStatus = BugStatus.OPEN;
  statusInProgress: BugStatus = BugStatus.IN_PROGRESS;
  statusResolved: BugStatus = BugStatus.RESOLVED;
}
