
import PageHeader from "@/components/common/header/PageHeader.vue";
import store from "@/main";
import DropDown from "@/components/common/dropdown/DropDown.vue";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { version } from "@/../package.json";
import { Options, Vue } from "vue-class-component";
import { getAllRecipes, getAllUsersByRole } from "@/components/FirebaseQueries";
import { convertStringToFormattedDate } from "@/components/GlobalFunctions";
import { UserRole } from "@/types/User";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/outline";

@Options({
  data() {
    return {
      contextUser: store.state.contextUser,
      isAdmin: store.state.isAdmin,
      loader: false,
      errorMessage: "",
      appVersion: version,
      allUsers: [],
    };
  },
  components: {
    CheckCircleIcon,
    XCircleIcon,
    LoaderCircle,
    DropDown,
    PageHeader,
  },
  async mounted() {
    this.loader = true;
    this.allUsers = [
      ...(await getAllUsersByRole(UserRole.USER)),
      ...(await getAllUsersByRole(UserRole.ADMIN)),
    ].sort((a, b) => {
      let dateA = a.lastSeenDate ? this.parseDate(a.lastSeenDate) : new Date(0);
      let dateB = b.lastSeenDate ? this.parseDate(b.lastSeenDate) : new Date(0);

      return dateB - dateA;
    });
    this.loader = false;
  },
  methods: {
    convertStringToFormattedDate,
    async exportRecipes() {
      const recipes = await getAllRecipes();
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(JSON.stringify(recipes))
      );
      element.setAttribute("download", "recipes");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    parseDate(dateString: string): Date {
      const date = new Date(dateString);
      return isNaN(date.getTime()) ? new Date(0) : date;
    },
  },
})
export default class AdminDashboard extends Vue {
  successMessages: string[] = [];
}
