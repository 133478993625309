
import { convertStringToFormattedDate } from "@/components/GlobalFunctions";
import ProfileProfilePicture from "@/components/profile/ProfileProfilePicture.vue";
import { Options, Vue } from "vue-class-component";
import { User } from "@/types/User";
import store from "@/main";
import ModalView from "@/components/modal/ModalView.vue";

@Options({
  props: {
    user: Object,
    profilePictureEditable: {
      type: Boolean,
      default: true,
    },
  },
  components: { ModalView, ProfileProfilePicture },
  created() {
    if (this.user !== undefined) this.contextUser = this.user;
    this.creationDateFormatted = convertStringToFormattedDate(
      this.contextUser?.creationDate || ""
    );
    this.lastSeenDateFormatted = this.contextUser?.lastSeenDate
      ? convertStringToFormattedDate(this.contextUser?.lastSeenDate || "")
      : "";
  },
  methods: {
    updateUsername() {
      store.commit("updateWalkthroughUsername", this.nUsername);
      this.isOpen = false;
    },
  },
})
export default class ProfileHeader extends Vue {
  contextUser: User = store.state.contextUser;
  creationDateFormatted = "";
  lastSeenDateFormatted = "";
  isOpen = false;
  nUsername = store.state.contextUser.displayName;
}
