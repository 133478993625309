import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58be20e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-recipe-private" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, [
      _createTextVNode(" Private recipe "),
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nPrivate) = $event)),
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateParent && _ctx.updateParent(...args)))
      }, null, 544), [
        [_vModelCheckbox, _ctx.nPrivate]
      ])
    ])
  ]))
}