
import {
  addEvent,
  deleteAndAcceptFriendRequest,
  rejectFriendRequest,
  removeFriend,
  sendFriendRequest,
} from "@/components/FirebaseMutations";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import store from "@/main";
import { UserAddIcon, UserRemoveIcon } from "@heroicons/vue/outline";
import { Options, Vue } from "vue-class-component";
import {
  getMeFriendRequest,
  getMeFriendStatus,
} from "@/components/FirebaseQueries";
import { FriendStatus, User } from "@/types/User";
import { PropType } from "vue";
import { EventType } from "@/types/Event";

@Options({
  components: { LoaderCircle, UserAddIcon, UserRemoveIcon },
  emits: ["onFriendRemoved", "onFriendMutated"],
  props: {
    user: Object as PropType<User>,
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      contextUserId: store.state.contextUser._id,
    };
  },
  async mounted() {
    this.status = await getMeFriendStatus(this.user._id);
    this.linkable = this.status === FriendStatus.ACCEPTED;
    this.loading = false;
  },
  methods: {
    async sendFriendRequest() {
      this.loading = true;
      // You don't know eachother
      if (this.status === FriendStatus.UNKNOWN) {
        await sendFriendRequest(this.user);
        await addEvent(EventType.SENT_FRIEND_REQUEST, {
          friendRequestSentTo: this.user?._id,
        });
      }
      // They are awaiting your response to their request
      if (this.status === FriendStatus.AWAITING_RESPONSE) {
        const request = await getMeFriendRequest(this.user._id);
        await deleteAndAcceptFriendRequest(request, this.user._id);
        await addEvent(EventType.ADDED_FRIEND, {
          friendRequestSentTo: this.user?._id,
        });
      }
      // Your request that you've sent is pending. You are awaiting their response
      this.loading = false;
      this.$emit("onFriendMutated", this.user);
    },
    async removeFriend() {
      this.loading = true;
      if (this.status === FriendStatus.PENDING) {
        const request = await getMeFriendRequest(this.user._id);
        await rejectFriendRequest(request);
      }
      if (this.status === FriendStatus.ACCEPTED) {
        if (!confirm("Are you sure you want to remove this friend?")) {
          this.loading = false;
          return;
        }
        await removeFriend(this.user._id);
      }
      this.loading = false;
      this.$emit("onFriendMutated", this.user);
    },
  },
})
export default class BlockProfileFriend extends Vue {
  status?: FriendStatus;
  statusPending: FriendStatus = FriendStatus.PENDING;
  statusAccepted: FriendStatus = FriendStatus.ACCEPTED;
  statusAwaitingResponse: FriendStatus = FriendStatus.AWAITING_RESPONSE;
  statusUnknown: FriendStatus = FriendStatus.UNKNOWN;
  linkable = false;
}
