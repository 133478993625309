
import RecipeBlocksTableView from "@/components/recipe/view/RecipeBlocksTableView.vue";
import RecipeTagsFilter from "@/components/recipe/components/RecipeTagsFilter.vue";
import { Options, Vue } from "vue-class-component";
import { RecipesByTag } from "@/types/Recipe";
import { PropType } from "vue";

@Options({
  emits: ["updatedFilterTags"],
  components: { RecipeTagsFilter, RecipeBlocksTableView },
  props: {
    recipes: Array as PropType<RecipesByTag[]>,
    showFilter: Boolean,
  },
})
export default class RecipeBlocksByTagView extends Vue {}
