import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/LoginView.vue";
import Recipes from "../components/recipe/view/RecipesView.vue";
import AddRecipe from "../components/recipe/create/AddRecipe.vue";
import RecipeDetailsView from "../components/recipe/view/RecipeDetailsView.vue";
import RecipeDetailsModify from "../components/recipe/modify/ModifyRecipe.vue";
import Signup from "../views/SignupView.vue";
import Profile from "../components/profile/ProfileView.vue";
import AdminDashboard from "../components/admin/AdminDashboard.vue";
import FriendsView from "../components/profile/friends/FriendsView.vue";
import Walkthrough from "../components/walkthrough/WalkthroughView.vue";
import Bugs from "../components/bugs/BugsReport.vue";
import TimeLine from "../components/timeline/Timeline.vue";
import RecipeBook from "@/views/RecipeBook.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: RecipeBook,
    children: [
      {
        path: "/",
        name: "Timeline",
        component: TimeLine,
      },
      {
        path: "/Walkthrough",
        name: "Walkthrough",
        component: Walkthrough,
      },
      {
        path: "/Timeline",
        name: "Timeline",
        component: TimeLine,
      },
      {
        path: "Profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "Recipes",
        name: "Recipes",
        component: Recipes,
      },
      {
        path: "Recipe/Add",
        name: "Add-Recipe",
        component: AddRecipe,
      },
      {
        path: "Recipe/:id",
        name: "Recipe_detail_view",
        component: RecipeDetailsView,
      },
      {
        path: "Recipe/Modify/:id",
        name: "Modify_recipe_view",
        component: RecipeDetailsModify,
      },
      {
        path: "Admin",
        name: "Admin",
        component: AdminDashboard,
      },
      {
        path: "Friend/:id",
        name: "Friend_view",
        component: FriendsView,
      },
      {
        path: "Bugs",
        name: "Bugs",
        component: Bugs,
      },
    ],
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path: "/Sign-up",
    name: "Signup",
    component: Signup,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
