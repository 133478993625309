export interface UserFavoriteRecipe {
  recipeId: string;
  authorId: string;
}

export enum FriendStatus {
  UNKNOWN = "UNKNOWN",
  AWAITING_RESPONSE = "AWAITING_RESPONSE",
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
}

export enum UserRole {
  ADMIN = "ADMIN",
  USER = "USER",
  DEMO = "DEMO",
}

export interface User {
  _id: string;
  role: UserRole;
  displayName: string;
  creationDate: string;
  email: string | null;
  profilePicture: string | null;
  walkthroughComplete: boolean;
  favouriteRecipes: UserFavoriteRecipe[];
  lastSeenDate: string;
}
