import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c5021f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-user-event-component" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "user-event-name-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: `/Friend/${$props.user._id}`
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: 
          $props.user.profilePicture ||
          require('@/assets/profile/placeholder_profile_picture.png')
        ,
          alt: ""
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}