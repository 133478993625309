
import { convertStringToFormattedDate } from "@/components/GlobalFunctions";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { Options, Vue } from "vue-class-component";
import { User } from "@/types/User";
import { getUserById } from "@/components/FirebaseQueries";
import { PropType } from "vue";
import { PencilIcon, TrashIcon } from "@heroicons/vue/outline";
import store from "@/main";
import { Comment } from "@/types/Comment";

@Options({
  components: { LoaderCircle, PencilIcon, TrashIcon },
  props: {
    comment: Object as PropType<Comment>,
  },
  async mounted() {
    this.author = await getUserById(this.comment.authorId);
    this.formattedDate = convertStringToFormattedDate(
      this.comment.creationDate
    );
    this.isAuthor = this.author._id === store.state.contextUser._id;
    this.loading = false;
  },
})
export default class CommentView extends Vue {
  author?: User;
  loading = true;
  formattedDate = "";
  isAuthor = false;
}
