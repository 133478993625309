import { User } from "@/types/User";
import { Event, EventType } from "@/types/Event";
import firebase from "firebase/app";
import store from "@/main";

export const InvalidUsernames = ["Null", "None", "Admin"];

export const DefaultReturnPath = "/Timeline";

export const getErrorCodeMessage = (errorCode: string): string => {
  switch (errorCode) {
    case "auth/user-not-found":
      return "User with given e-mail does not exist.";
    case "auth/wrong-password":
      return "The given email or password is incorrect.";
    case "auth/passwords-do-not-match":
      return "Given passwords do not match.";
    case "auth/email-already-in-use":
      return "An account already exists with this e-mail.";
    case "auth/username-invalid-characters":
      return "You have invalid characters in your username";
    case "auth/username-not-allowed":
      return "This username is not allowed";
    default:
      return "";
  }
};

export function getMessageFromEventType(event: Event, user: User) {
  switch (event.type) {
    case EventType.DEMO:
      return `${user.displayName} created a new demo event.`;
    case EventType.ADDED_RECIPE:
      return `${user.displayName} added a new recipe: <a style="text-decoration: underline" href="${event.data?.linkUrl}">${event.data?.recipeName}</a>`;
    case EventType.ADDED_COMMENT:
      return `${user.displayName} commented on your recipe: <a style="text-decoration: underline" href="${event.data?.linkUrl}">${event.data?.recipeName}</a>`;
    case EventType.ADDED_BUG:
      return `${user.displayName} added a bug-report: <a style="text-decoration: underline" href="/Bugs">${event.data?.bugTitle}</a>`;
    case EventType.SENT_FRIEND_REQUEST:
      return `${user.displayName} sent you a friend-request! <a style="text-decoration: underline" href="/Profile">Click here to repond</a>`;
    case EventType.ADDED_FRIEND:
      return `You and <a style="text-decoration: underline" href="/Friend/${user._id}">${user.displayName}</a> are now friends!`;
  }
}

export const collectionBugs = () => {
  return firebase.firestore().collection(store.state.COLLECTION_BUGS);
};

export const collectionUsers = () => {
  return firebase.firestore().collection(store.state.COLLECTION_USERS);
};

export const collectionTags = () => {
  return firebase.firestore().collection(store.state.COLLECTION_TAGS);
};

export const collectionRecipes = () => {
  return firebase.firestore().collection(store.state.COLLECTION_RECIPES);
};
