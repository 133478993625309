
import firebase from "firebase/app";
import store from "@/main";
import { resizeImage } from "@/components/GlobalFunctions";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["onProfilePictureUpdated"],
  components: { LoaderCircle },
  props: {
    profilePicture: String,
    editable: {
      type: Boolean,
      default: true,
    },
    showEdit: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      displayPicture: this.profilePicture,
      loading: false,
      contextUserId: store.state.contextUser._id,
    };
  },
  methods: {
    uploadProfilePicture() {
      if (this.editable) {
        document.getElementById("image-upload")?.click();
      }
    },
    async onFileUpload() {
      this.loading = true;
      resizeImage({
        file: (document.getElementById("image-upload") as any).files[0],
        maxSize: 500,
      }).then((file) => {
        firebase
          .storage()
          .ref(store.state.COLLECTION_USERS + "/" + this.contextUserId)
          .child("/profilePicture")
          .put(file as Blob | Uint8Array | ArrayBuffer)
          .then((result) => {
            result.ref.getDownloadURL().then((value) => {
              this.displayPicture = value;
              this.uploadFirestoreWithProfilePicture(value);
            });
          })
          .finally(() => (this.loading = false));
      });
    },
    uploadFirestoreWithProfilePicture(url: string) {
      firebase
        .firestore()
        .collection(store.state.COLLECTION_USERS)
        .doc(this.contextUserId)
        .update({ profilePicture: url })
        .then(() => this.$emit("onProfilePictureUpdated", url));
    },
  },
})
export default class ProfileProfilePicture extends Vue {}
