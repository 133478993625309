
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["time", "updateRecipeData"],
  props: {
    type: {
      type: String,
      default: "PREP",
    },
    time: {
      type: Number,
      default: 0,
    },
  },
  beforeMount() {
    this.nTime = this.time;
  },
  methods: {
    updateParent() {
      Math.abs(this.nTime);
      this.$emit("updateRecipeData", {
        key: this.type === "PREP" ? "prepTime" : "cookTime",
        value: this.nTime,
      });
    },
  },
})
export default class RecipeEstimatedTime extends Vue {
  nTime?: number;
}
