import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "recipe-tags",
    key: $props.tags,
    style: _normalizeStyle({
      display: $props.wrap ? 'flex' : '',
      flexWrap: $props.wrap ? 'wrap' : 'no-wrap',
    })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tags, (tag) => {
      return (_openBlock(), _createElementBlock("p", {
        key: tag.name
      }, _toDisplayString(tag.name), 1))
    }), 128))
  ], 4))
}