
import store from "@/main";
import UserIconName from "@/components/common/usercard/UserIconName.vue";
import {
  addEvent,
  deleteAndAcceptFriendRequest,
  rejectFriendRequest,
  sendFriendRequest,
} from "@/components/FirebaseMutations";
import LoaderCircle from "@/components/common/loader/LoaderCircle.vue";
import { CheckIcon, UserAddIcon, UserRemoveIcon } from "@heroicons/vue/outline";
import { Options, Vue } from "vue-class-component";
import { EventType } from "@/types/Event";
import { PropType } from "vue";
import { User } from "@/types/User";

@Options({
  components: {
    LoaderCircle,
    UserIconName,
    UserAddIcon,
    UserRemoveIcon,
    CheckIcon,
  },
  props: {
    user: Object as PropType<User>,
    mode: Number,
    request: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      requestSent: false,
      loading: false,
      contextUserId: store.state.contextUser._id,
      isSelected: false,
    };
  },
  methods: {
    async acceptFriendRequest() {
      this.loading = true;
      await deleteAndAcceptFriendRequest(this.request, this.user._id);
      await addEvent(EventType.ADDED_FRIEND, {
        friendRequestSentTo: this.user?._id,
      });
      this.loading = false;
      this.$emit("onFriendMutated");
    },
    async rejectFriendRequest() {
      if (!confirm("Are you sure you want to reject this user?")) return;
      this.loading = true;
      await rejectFriendRequest(this.request);
      this.loading = false;
      this.$emit("onFriendMutated");
    },
    async removeFriendRequest() {
      if (!confirm("Are you sure you want to remove this friend request?"))
        return;
      this.loading = true;
      await rejectFriendRequest(this.request);
      this.loading = false;
      this.$emit("onFriendMutated");
    },
    async sendFriendRequest() {
      this.loading = true;
      await sendFriendRequest(this.user);
      await addEvent(EventType.SENT_FRIEND_REQUEST, {
        friendRequestSentTo: this.user?._id,
      });
      this.loading = false;
      this.$emit("onFriendMutated");
    },
  },
})
export default class AddFriendsListRow extends Vue {}
