import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5be67a22"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "wrapper-profile"
}
const _hoisted_2 = { class: "container-content-profile" }
const _hoisted_3 = { id: "container-blocks" }
const _hoisted_4 = { class: "container-profile-elements" }
const _hoisted_5 = { class: "container-profile-elements" }
const _hoisted_6 = { class: "container-archived-recipes" }
const _hoisted_7 = { class: "container-archived-recipes" }
const _hoisted_8 = { class: "signout-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileHeader = _resolveComponent("ProfileHeader")!
  const _component_BlockProfileFriends = _resolveComponent("BlockProfileFriends")!
  const _component_LoaderCircle = _resolveComponent("LoaderCircle")!
  const _component_BlockProfileTags = _resolveComponent("BlockProfileTags")!
  const _component_ProfileFriendsRecipes = _resolveComponent("ProfileFriendsRecipes")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ProfileHeader),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BlockProfileFriends)
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.loadingTags)
                ? (_openBlock(), _createBlock(_component_LoaderCircle, { key: 0 }))
                : (_openBlock(), _createBlock(_component_BlockProfileTags, {
                    key: 1,
                    tags: _ctx.tags,
                    onOpenModalTags: _ctx.openViewTags
                  }, null, 8, ["tags", "onOpenModalTags"]))
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_ProfileFriendsRecipes, {
              friend: this.contextUser,
              status: "DRAFT"
            }, null, 8, ["friend"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ProfileFriendsRecipes, {
              friend: this.contextUser,
              status: "ARCHIVED"
            }, null, 8, ["friend"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("input", {
              class: "signout",
              type: "submit",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
              value: "Signout"
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}